import React, { Component } from 'react';
import { Input, Button } from 'semantic-ui-react';

import styles from './Password.module.scss';

class Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ['##############', '##############'],
      changed: false,
    };
  }

  componentWillReceiveProps(nextProps) {}

  onTextChanged(index, text) {
    let oldText = this.state.text;
    if (!this.state.changed) {
      oldText = ['', ''];
      text = '';
      this.setState({ changed: true });
    }

    let newText = [...oldText];
    newText[index] = text;
    const different = newText[0] !== newText[1] || newText[0] === '';
    this.setState({ text: newText, different });

    this.props.onChanged(newText[0], !different);
  }

  render() {
    const { different, text } = this.state;

    return (
      <div>
        <div>Password:</div>
        {text.map((text, index) => {
          return (
            <div key={index}>
              <Input
                value={text}
                type="password"
                onChange={(event) => {
                  this.onTextChanged(index, event.target.value);
                }}
              ></Input>
            </div>
          );
        })}
        {different && <div className={styles.diff}>Different</div>}{' '}
      </div>
    );
  }
}

export default Password;
