import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './Stations.module.scss';
import { connect } from 'react-redux';
import {
  actionSetUser,
  actionSetUsers,
} from '../../../../redux/actions/actionUser';
import store from '../../../../redux/store';
import { Button, Icon, Card, Popup } from 'semantic-ui-react';

import RestAPI from '../../../../RestAPI.js';
import DataTable from '../../../3_organisms/DataTable/DataTable';
import ModalOkCancel from '../../../3_organisms/ModalOkCancel';

import { includesOneOf } from '../../../../helpers/scopecheck';
import { actionSetGame } from '../../../../redux/actions/actionGames';
import UUID from 'uuid/v4';
import { pairFiles, combiFiles } from './../../../../helpers/media';

class Stations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userToDelete: null,
    };
  }

  updateView() {
    store.dispatch(actionSetUsers(null));
    RestAPI.users();
  }

  onSelect(user) {
    store.dispatch(actionSetUser(user));
  }

  onAddNew(name) {
    const game = this.props.games.find((game) => game.name === 'paarsuche');

    let station = {
      name: name,
      center_uuid: this.props.center.uuid,
      scope: ['station'],
      active: true,
      game_uuid: game.uuid,
      created: new Date().toISOString(),
      modified: new Date().toISOString(),
      game_settings: {
        paarsuche: {
          col: 4,
          row: 4,
          mode: 'pairs',
          duration: 90,
          pricesPerDay: 1,
        },
        timer: {
          active: false,
          start: '09:00',
          end: '21:00',
        },
        cursorMode: 'smooth',
      },
    };

    // create pair cards
    station.game_settings.paarsuche['pairs'] = Array.apply(
      null,
      Array(pairFiles.length)
    ).map((_, index) => {
      return {
        uuid: UUID(),
        media_uuids: [null],
        default_images: [pairFiles[index]],
        name: [],
      };
    });

    station.game_settings.paarsuche['combis'] = Array.apply(
      null,
      Array(combiFiles.length)
    ).map((_, index) => {
      return {
        uuid: UUID(),
        media_uuids: [null, null],
        default_images: [
          `${combiFiles[index]}_a.jpg`,
          `${combiFiles[index]}_b.jpg`,
        ],
        name: [],
      };
    });

    station.game_settings.paarsuche['winners'] = [
      {
        uuid: UUID(),
        media_uuids: [null, null],
        name: [],
      },
    ];

    RestAPI.createUser(station).then((res) => {
      if (res && !res.error) {
        this.updateView();
      }
    });
  }

  onDelete(user) {
    RestAPI.deleteUser(user).then((res) => {
      if (res && !res.error) {
        store.dispatch(actionSetUser(null));
        this.updateView();
      }
    });
  }

  onSet(user) {
    if (user) {
      const game = this.props.games.find(
        (game) => game.uuid === user.game_uuid
      );
      store.dispatch(actionSetUser(user));
      store.dispatch(actionSetGame(game));
      this.props.history.push('/admin/station/' + user.uuid);
    }
  }

  render() {
    const { center, users, login, games } = this.props;

    const { userToDelete } = this.state;

    const isRoot = includesOneOf(login && login.scope, ['root']);
    const isAdmin = includesOneOf(login && login.scope, ['admin']);

    const rows =
      users &&
      center &&
      games &&
      users
        .filter((user) => {
          return user.center_uuid === center.uuid;
        })
        .filter((user) => {
          return includesOneOf(user.scope, ['station']);
        })
        .map((user, index) => {
          const game = games.find((entry) => entry.uuid === user.game_uuid);
          return {
            name: user.name,
            game: game ? game.name : 'not set',
            user: user,
          };
        });

    return (
      <div className={styles.main}>
        {isRoot ? (
          <h3>Stations of Center: {center && center.name}</h3>
        ) : (
          <h3>Stations:</h3>
        )}
        <div>
          {rows &&
            rows.map((station) => {
              return (
                <div key={station.user.uuid} className={styles.card}>
                  <Card>
                    <Card.Content>
                      <Card.Header>Paarsuche</Card.Header>
                      <p />
                      <img
                        src={process.env.PUBLIC_URL + '/assets/paarsuche.png'}
                        className={styles.image}
                        alt="_image"
                      />

                      <Card.Meta></Card.Meta>
                      <Card.Description>
                        Login Name: {station.name}
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <Popup
                        content="Time period"
                        trigger={
                          <div>
                            <Icon name="clock" />
                            {station.user.game_settings.timer.active ? (
                              <>
                                {station.user.game_settings.timer.start}
                                {' - '}
                                {station.user.game_settings.timer.end}
                              </>
                            ) : (
                              '	permanent running'
                            )}
                          </div>
                        }
                      />
                    </Card.Content>
                    <div className={styles.buttons}>
                      <Button.Group>
                        <Popup
                          content="Change properties of the station"
                          trigger={
                            <Button
                              icon
                              onClick={() => {
                                this.onSet(station.user);
                              }}
                            >
                              <Icon name={'setting'} />
                              Edit
                            </Button>
                          }
                        />
                        {isRoot && (
                          <Button
                            icon
                            color={'red'}
                            onClick={() => {
                              this.setState({ userToDelete: station.user });
                            }}
                          >
                            <Icon name={'delete'} />
                            Delete
                          </Button>
                        )}
                      </Button.Group>
                    </div>
                  </Card>
                </div>
              );
            })}
        </div>
        <p />
        <p />
        {isRoot && (
          <Button
            icon
            onClick={() => {
              this.onAddNew('new station');
            }}
          >
            <Icon name="add" />
            Add new station
          </Button>
        )}
        <ModalOkCancel
          text="Delete station?"
          color="red"
          open={userToDelete != null}
          onOK={() => {
            this.onDelete(userToDelete);
            this.setState({ userToDelete: null });
          }}
          onCancel={() => this.setState({ userToDelete: null })}
        />
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Stations = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    users: state.user.users,
    centers: state.center.centers,
    games: state.game.games,
    center: state.center.center,
  }),
  {} // bind account loading action creator
)(Stations);

export default withRouter(Stations);
