import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './Sessions.module.scss';
import { connect } from 'react-redux';
import store from '../../../../redux/store';

import DataTable from '../../../3_organisms/DataTable/DataTable';

import { includesOneOf } from '../../../../helpers/scopecheck';
import { actionSetSession } from '../../../../redux/actions/actionSession';
import { centers } from '../../../../RestAPI';

class Sessions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateView() {
    //    RestAPI.sessions(this.props.center.uuid);
  }

  onSelect(session) {
    store.dispatch(actionSetSession(session));
  }

  onSet(session) {
    if (session) {
      store.dispatch(actionSetSession(session));
      this.props.history.push('/admin/session/' + session.uuid);
    }
  }

  render() {
    const { center, centers, sessions, login } = this.props;

    const isRoot = includesOneOf(login && login.scope, ['root']);

    let rows =
      sessions &&
      (center || centers) &&
      sessions
        .filter((session) => session.started)
        .filter(
          (session) => isRoot || (center && session.center_uuid === center.uuid)
        )
        .map((session) => {
          const sessionCenter =
            centers &&
            centers.find((entry) => entry.uuid === session.center_uuid);
          return {
            started: session.started,
            center: sessionCenter && sessionCenter.name,
            ended: session.ended,
            session: session,
            code: session.code,
            text: session.text,
            prices: session.prices ? '🎁' : '',
            finished: session.finished ? '✔️' : '',
          };
        });

    if (!isRoot) {
      rows = rows && rows.filter((entry) => (entry.code ? true : false));
    }

    const actions = [
      {
        icon: 'external',
        color: 'blue',
        tooltip: 'edit note',
        func: (row) => this.onSet(row.session),
      },
    ];

    const actionsMenu = [
      {
        text: 'Add Note',
        icon: 'sticky note outline',
        color: 'blue',
        tooltip: 'Edit note',
        func: (row) => this.onSet(row.session),
      },
    ];

    const columns = [
      isRoot && {
        name: 'Center',
        key: 'center',
      },
      {
        name: 'Date',
        key: 'started',
        type: 'date',
        required: true,
        singleLine: true,
      },
      {
        name: 'Winner Code',
        key: 'code',
        required: true,
        singleLine: true,
      },
      {
        name: 'Notes',
        key: 'text',
        required: true,
        singleLine: true,
      },
      isRoot && {
        name: 'Finished',
        key: 'finished',
      },
      isRoot && {
        name: 'Prices',
        key: 'prices',
      },
    ];

    return (
      <div className={styles.main}>
        <h3>Sessions:</h3>
        <div>
          <DataTable
            tableProps={{ selectable: false, compact: true, celled: true }}
            name="stations"
            placeholder="Filter name"
            sortable={true}
            columns={columns}
            rows={rows}
            actions={actions}
            actionsMenu={actionsMenu}
            updateView={() => this.updateView()}
            pagination={true}
          />
        </div>
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Sessions = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    users: state.user.users,
    sessions: state.session.sessions,
    games: state.game.games,
    center: state.center.center,
    centers: state.center.centers,
  }),
  {} // bind account loading action creator
)(Sessions);

export default withRouter(Sessions);
