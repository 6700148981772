import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Icon, Button, Message, Popup, Card } from 'semantic-ui-react';
import EditLine from '../../../1_atom/EditLine/EditLine.js';

import { connect } from 'react-redux';
import store from '../../../../redux/store';

import styles from './Center.module.scss';

import RestAPI from '../../../../RestAPI.js';
import { actionSetCenter } from '../../../../redux/actions/actionCenter.js';
import UploadButton from '../../../3_organisms/UploadButton/UploadButton.js';
const clone = require('clone');

class Center extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      apply: false,
      valid: true,
    };
  }

  updateView() {
    RestAPI.media(this.props.center.uuid);
    //    RestAPI.center(this.props.center.uuid);
  }

  onApply() {
    if (!this.props.center) {
      return false;
    }

    let center = {
      ...this.props.center,
    };
    center.name =
      this.state.name !== '' ? this.state.name : this.props.center.name;

    store.dispatch(actionSetCenter(center));

    RestAPI.updateCenter(center).then((result) => {
      RestAPI.centers();
      this.props.history.push('/admin/centers');
    });

    this.setState({ apply: false });
  }

  render() {
    const { center, centers, media } = this.props;
    const { apply, valid } = this.state;

    const center_image_media =
      media && media.find((entry) => entry.uuid === center.image_media_uuid);

    const shirt_image_media =
      media && media.find((entry) => entry.uuid === center.shirt_media_uuid);

    return (
      <div className={styles.main}>
        {center && (
          <div>
            <EditLine
              label="Name:"
              text={center.name}
              onChanged={(text) => {
                const valid = !centers.find(
                  (u) => u.name === text && u.name !== center.name
                );
                this.setState({ name: text, apply: valid, valid });
              }}
            />
            {!valid && <div style={{ color: 'red' }}>name exists</div>}
            <Message>
              <div> Center Image:</div>

              <div className={styles.ui}>
                <div className={styles.ui_control}>
                  <Card.Group style={{ width: '210px' }}>
                    <Card>
                      <Card.Content>
                        {center_image_media ? (
                          <div>
                            <div>
                              <Popup
                                disabled={center_image_media ? false : true}
                                content={
                                  center_image_media && center_image_media.name
                                }
                                trigger={
                                  <img
                                    className={styles.centerImage}
                                    src={`${RestAPI.getRoot()}/media/${
                                      center_image_media.center_uuid
                                    }&${center_image_media.filename}`}
                                    alt="_image"
                                  />
                                }
                              />
                            </div>
                            <Button
                              size={'mini'}
                              icon
                              onClick={() => {
                                let newCenter = clone(center);
                                newCenter.image_media_uuid = null;
                                store.dispatch(actionSetCenter(newCenter));
                                this.setState({ apply: true });
                              }}
                            >
                              <Icon name="delete" />
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <img
                              className={styles.centerImage}
                              src={process.env.PUBLIC_URL + '/assets/empty.png'}
                              alt="_image"
                            />
                            <UploadButton
                              label={'Upload new center image'}
                              media={center_image_media}
                              onChanged={(media) => {
                                let newCenter = clone(center);
                                newCenter.image_media_uuid = media.uuid;
                                store.dispatch(actionSetCenter(newCenter));
                                this.setState({ apply: true });
                              }}
                            />
                          </div>
                        )}
                      </Card.Content>
                    </Card>
                  </Card.Group>
                </div>
                <div className={styles.ui_text}>
                  <p>
                    Image will be shown in the upper left corner of the game.
                  </p>
                  <p>The image have to be square and the max resolution</p>
                  <p>should not be greater than 1024 x 1024 pixel</p>
                </div>
              </div>
            </Message>{' '}
            <Message>
              <div> Shirt Image:</div>

              <div className={styles.ui}>
                <div className={styles.ui_control}>
                  <Card.Group style={{ width: '210px' }}>
                    <Card>
                      <Card.Content>
                        {shirt_image_media ? (
                          <div>
                            <div>
                              <Popup
                                disabled={shirt_image_media ? false : true}
                                content={
                                  shirt_image_media && shirt_image_media.name
                                }
                                trigger={
                                  <img
                                    className={styles.centerImage}
                                    src={`${RestAPI.getRoot()}/media/${
                                      shirt_image_media.center_uuid
                                    }&${shirt_image_media.filename}`}
                                    alt="_image"
                                  />
                                }
                              />
                            </div>
                            <Button
                              size={'mini'}
                              icon
                              onClick={() => {
                                let newCenter = clone(center);
                                newCenter.shirt_image_media = null;
                                store.dispatch(actionSetCenter(newCenter));
                                this.setState({ apply: true });
                              }}
                            >
                              <Icon name="delete" />
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <img
                              className={styles.centerImage}
                              src={process.env.PUBLIC_URL + '/assets/empty.png'}
                              alt="_image"
                            />
                            <UploadButton
                              label={'Upload new shirt image'}
                              media={center_image_media}
                              onChanged={(media) => {
                                let newCenter = clone(center);
                                newCenter.shirt_image_media = media.uuid;
                                store.dispatch(actionSetCenter(newCenter));
                                this.setState({ apply: true });
                              }}
                            />
                          </div>
                        )}
                      </Card.Content>
                    </Card>
                  </Card.Group>
                </div>
                <div className={styles.ui_text}>
                  <p>Image will be shown on the shirt of the avatar.</p>
                  <p>The image have to be square and the max resolution</p>
                  <p>should not be greater than 1024 x 1024 pixel</p>
                </div>
              </div>
            </Message>{' '}
            {apply && (
              <Button
                floated="left"
                color={'green'}
                onClick={() => this.onApply()}
              >
                Apply
              </Button>
            )}{' '}
          </div>
        )}
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Center = connect(
  (state) => ({
    center: state.center.center,
    centers: state.center.centers,
    media: state.media,
  }),
  {}
)(Center);

export default withRouter(Center);
