const initialState = [];

const reducerMedia = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MEDIA':
      return action.media;
    case 'ADD_MEDIA':
      return [...state, action.media];
    case 'UPDATE_MEDIA':
      return state.map((media) =>
        media && media.uuid === action.uuid
          ? { ...media, ...action.update }
          : media
      );
    case 'REMOVE_MEDIA':
      return state.filter((media) => media && media.uuid !== action.media);
    default:
      return state;
  }
};

export default reducerMedia;
