import React, { Component } from 'react';

import { Modal, Header, Button } from 'semantic-ui-react';
import EditInline from '../../1_atom/EditLine/EditLine';

class NewRowModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { title, icon, fields, onApply, onCancel, onValid } = this.props;
    const { valid } = this.state;

    const actualFields = fields.filter((fields) => fields && !fields.disabled);

    let canApply = true;

    console.log(fields);

    for (let field of actualFields) {
      if (field.required) {
        if (!this.state[field.key]) {
          canApply = false;
        }
      }
    }

    return (
      <Modal open={true} size={'tiny'}>
        <Header icon={icon} content={title} />
        <Modal.Content>
          {actualFields.map((conf) => {
            let valid = true;
            if (onValid && this.state[conf.key]) {
              valid = onValid(this.state[conf.key], conf.key);
            }
            return (
              <div key={conf.name}>
                <EditInline
                  label={conf.name + ':'}
                  type={conf.type}
                  onChanged={(text) => {
                    const state = {
                      valid: onValid ? onValid(text, conf.key) : true,
                    };
                    state[conf.key] = text;
                    this.setState(state);
                  }}
                />
                {!valid && <div style={{ color: 'red' }}>name exists</div>}
              </div>
            );
          })}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            disabled={!canApply || !valid}
            onClick={() => onApply(this.state)}
          >
            apply
          </Button>
          <Button onClick={onCancel}>cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

NewRowModal.defaultProps = {
  title: 'New Row',
  icon: 'add',
};

export default NewRowModal;
