import RestAPI from '../RestAPI.js';

export const pairFiles = Array.apply(null, Array(29)).map((_, index) => {
  return `assets/cards/pairs/card${index}.jpg`;
});

export const combiFiles = [
  'blumen',
  'brot',
  'eis',
  'geschenk',
  'handtasche',
  'hunde',
  'loewen',
  'ring',
  'schirm',
  'hahn',
].map((name, index) => {
  return `assets/cards/combi/${name}`;
});

export const getMediaURL = (media) => {
  return `${RestAPI.getRoot()}/media/${media.center_uuid}&${media.filename}`;
};
