export const actionSetDebugMode = (debugMode) => ({
  type: 'SET_DEBUG_MODE',
  debugMode: debugMode,
});

export const actionSetApiBase = (apibase) => ({
  type: 'SET_APIBASE',
  apibase: apibase,
});

export const actionSetXState = (xstate) => ({
  type: 'SET_XSTATE',
  xstate: xstate,
});

export const actionSetPath = (activePath) => ({
  type: 'SET_PATH',
  activePath: activePath,
});
