import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import StateMachine from '../../../../services/StateMachine';

import { connect } from 'react-redux';
import { actionSetCenter } from '../../../../redux/actions/actionCenter';
import store from '../../../../redux/store';
import { arrayDropDown } from '../../../../helpers/arrayDropDown';
import { includesOneOf } from '../../../../helpers/scopecheck';

import {
  Image,
  Dropdown,
  Menu,
  Visibility,
  Container,
} from 'semantic-ui-react';
import RestAPI from '../../../../RestAPI';

const CenterSelect = (props) => {
  const center = props.center;
  const centers = props.centers;
  const optCenters = arrayDropDown(centers);
  return (
    <Dropdown
      placeholder="Select Center"
      value={center}
      selection
      options={optCenters}
      onChange={(e, d) => {
        const center = centers.find((entry) => entry.uuid === d.value);
        store.dispatch(actionSetCenter(center));
      }}
    />
  );
};

const LoginInfo = (props) => {
  return (
    <Dropdown
      text={props.login && props.login.name}
      button
      className="icon"
      labeled
      icon="user"
    >
      <Dropdown.Menu>
        <Dropdown.Header
          content={'User: ' + (props.login && props.login.name)}
        />
        <Dropdown.Divider />
        <Dropdown.Item
          value={1}
          icon="log out"
          text="Logout"
          onClick={() => {
            StateMachine.send('LOGOUT');
          }}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

const menuStyle = {
  border: 'none',
  borderRadius: 0,
  boxShadow: 'none',
  marginBottom: '1em',
  marginTop: '0em',
  transition: 'box-shadow 0.5s ease, padding 0.5s ease',
};

const fixedMenuStyle = {
  border: '0px solid #ddd',
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
};

class DashboardContainer extends Component {
  state = {};

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    const isRoot = includesOneOf(this.props.login.scope, ['root']);

    return (
      <Container style={{ height: '100%' }}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Menu
            style={fixed ? fixedMenuStyle : menuStyle}
            fixed={fixed ? 'top' : null}
            pointing={!fixed}
            secondary={!fixed}
            size="large"
          >
            <Menu.Item position="left">
              <Image
                style={{ marginRight: '10px', height: '33px' }}
                src={process.env.PUBLIC_URL + '/assets/felix.png'}
              />
              <div style={{ color: 'white', fontSize: '2em' }}>
                Playit Admin Dashboard{' '}
              </div>
            </Menu.Item>
            <Menu.Item position="right">
              {isRoot && <CenterSelect {...this.props} />}{' '}
              <LoginInfo {...this.props} />
            </Menu.Item>
          </Menu>
        </Visibility>
        {children}
      </Container>
    );
  }
}

DashboardContainer = connect(
  (state) => ({
    login: state.login.login,
    centers: state.center.centers,
  }),
  {} // bind account loading action creator
)(DashboardContainer);

export default withRouter(DashboardContainer);
