import React, { Component } from 'react';

import {
  Menu,
  Pagination,
  Popup,
  Header,
  Button,
  Icon,
  Table,
  Dropdown,
} from 'semantic-ui-react';
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu';
import EditLine from '../../1_atom/EditLine/EditLine.js';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';

const CellStyled = styled.div``;

var stateArray = [];

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      selected: -1,
      activePage: 1,
      maxRows: 25,
      filterValue: '',
      rows: null,
      sort: {
        column: null,
        direction: null,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.rows && this.state.rows) {
      this.setState({ activePage: 1, rows: null });
    }
  }

  componentDidMount() {
    if (this.state.name !== this.props.name) {
      if (stateArray[this.props.name]) {
        console.log(
          '#DataTable componentDidMount use success: ',
          this.props.name
        );
        this.setState({ ...stateArray[this.props.name] });
        return;
      } else {
        console.log('#DataTable componentDidMount use failed', this.props.name);
      }
    } else {
      console.log(
        '#DataTable componentDidMount reset to default',
        this.props.name
      );
    }

    this.setState({
      name: this.props.name,
      activePage: 1,
      selected: -1,
      filterValue: '',
      rows: null,
    });
  }

  componentWillUnmount() {
    if (this.props.name !== undefined) {
      console.log('#DataTable componentWillUnmount save', this.props.name);
      stateArray[this.props.name] = this.state;
    }
  }

  handleSort = (clickedColumn) => () => {
    if (!this.props.sortable) return;

    const sort = this.state.sort;
    const rows = this.state.rows ? this.state.rows : this.props.rows;

    if (sort.column !== clickedColumn) {
      let sortrows = _.sortBy(rows, [clickedColumn]);

      this.setState({
        rows: sortrows,
        sort: {
          column: clickedColumn,
          direction: 'ascending',
        },
      });
      return;
    }

    this.setState({
      rows: rows.reverse(),
      sort: {
        column: sort.column,
        direction: sort.direction === 'ascending' ? 'descending' : 'ascending',
      },
    });
  };

  render() {
    let {
      filter,
      columns,
      actions,
      actionsMenu,
      sortable,
      tableProps = {
        compact: true,
        celled: true,
      },
      pagination,
    } = this.props;
    let { filterValue, maxRows, activePage, sort, selected } = this.state;

    const rows = this.state.rows ? this.state.rows : this.props.rows;

    let totalPages = (rows && Math.ceil(rows.length / maxRows)) || 1;

    let opt = [
      { key: '5', text: '5', value: 5 },
      { key: '10', text: '10', value: 10 },
      { key: '25', text: '25', value: 25 },
      { key: '100', text: '100', value: 100 },
      { key: '1000', text: '1000', value: 1000 },
    ];

    const renderButtons = (array, row, index) => {
      return (
        array &&
        array.map((button) => {
          if (!button) {
            return null;
          }

          const buttonComponent = (
            <Button
              size={'mini'}
              icon
              key={button.icon}
              floated={button.float}
              labelPosition={button.text && 'left'}
              color={button.color}
              onClick={() => button.func(row, index)}
            >
              <Icon name={button.icon} /> {button.text}
            </Button>
          );

          return button.tooltip ? (
            <Popup
              key={button.icon}
              trigger={buttonComponent}
              content={button.tooltip}
            />
          ) : (
            buttonComponent
          );
        })
      );
    };

    let filteredRows =
      rows &&
      rows
        .filter((row) => {
          if (filter) {
            if (row[filter] && row[filter].search(filterValue) !== -1)
              return true;
            else return false;
          } else return true;
        })
        .filter((row, index) => {
          if (Math.floor(index / maxRows) === activePage - 1) return true;
          else return false;
        });

    let dataitem = (item, key, type) => {
      let data = '';
      switch (type) {
        case 'date':
          data = item[key]
            ? moment(item[key]).format('DD:MM:YYYY HH:mm:ss')
            : '';
          break;
        default:
          data = item[key];
      }

      if (item.header)
        return (
          <Header as="h4" textAlign="left">
            {data}
          </Header>
        );
      else return data;
    };

    let tableRows =
      filteredRows &&
      filteredRows.map((row, index) => {
        return (
          <Table.Row
            key={index}
            onClick={() => {
              this.setState({ selected: index });
              if (this.props.onSelect)
                setTimeout(() => {
                  this.props.onSelect(row);
                }, 333); // need timout because the state is not updated when componentWillUnmount ist called
            }}
            active={index === selected}
          >
            {columns &&
              columns
                .filter((column) => column && !column.hidden)
                .map((column) => (
                  <Table.Cell key={column.key} singleLine={column.singleLine}>
                    <ContextMenuTrigger id={index.toString()}>
                      <CellStyled>
                        {column.render
                          ? column.render(row, column.key)
                          : dataitem(row, column.key, column.type)}
                      </CellStyled>
                    </ContextMenuTrigger>
                  </Table.Cell>
                ))}
            {actions && (
              <Table.Cell singleLine collapsing>
                <CellStyled>{renderButtons(actions, row, index)}</CellStyled>
              </Table.Cell>
            )}
          </Table.Row>
        );
      });

    const renderMenu = (array, row, index) => {
      return (
        array &&
        array.map((item, index) => {
          if (!item) {
            return null;
          }
          return (
            <Menu.Item
              key={index}
              icon
              color={item.color}
              onClick={() => {
                item.func(row, index);
              }}
            >
              <Icon name={item.icon} color={item.color} /> {item.text}
            </Menu.Item>
          );
        })
      );
    };

    // Context menue
    const contextMenue = actionsMenu && actionsMenu.filter((m) => !!m);
    const menuRows =
      contextMenue &&
      contextMenue.length > 0 &&
      filteredRows &&
      filteredRows.map((row, index) => {
        return (
          <ContextMenu key={index} id={index.toString()}>
            <Menu key={index} size="mini" vertical>
              {renderMenu(contextMenue, row, index)}
            </Menu>
          </ContextMenu>
        );
      });

    let fillEmptyRows = maxRows;
    if (tableRows) {
      if (tableRows.length !== maxRows && maxRows <= 10)
        fillEmptyRows = maxRows - tableRows.length;
      else fillEmptyRows = 0;
    } else tableRows = [];

    for (let i = 0; i < fillEmptyRows; i++) {
      tableRows.push(
        <Table.Row key={i + maxRows}>
          <Table.Cell colSpan={columns && columns.length + !!actions}>
            <CellStyled>
              {!rows && <Icon loading name="circle notched" />}
            </CellStyled>
          </Table.Cell>
        </Table.Row>
      );
    }

    return (
      <div>
        <Table {...tableProps} sortable={sortable}>
          <Table.Header>
            <Table.Row>
              {columns &&
                columns
                  .filter((column) => column && !column.hidden)
                  .map((column) => (
                    <Table.HeaderCell
                      key={column.key}
                      sorted={
                        column.key === sort.column ? sort.direction : null
                      }
                      onClick={this.handleSort(column.key)}
                    >
                      {column.name}
                    </Table.HeaderCell>
                  ))}
              {actions && <Table.HeaderCell />}
            </Table.Row>
          </Table.Header>

          <Table.Body>{tableRows}</Table.Body>
          {pagination === true && (
            <Table.Footer fullWidth>
              <Table.Row>
                <Table.HeaderCell
                  colSpan={columns && columns.length + !!actions}
                >
                  <div
                    style={{
                      display: 'inline',
                      marginRight: '10px',
                      marginLeft: '20px',
                    }}
                  >
                    {filter && (
                      <EditLine
                        placeholder="Filter..."
                        text={filterValue}
                        onChanged={(text) =>
                          this.setState({ filterValue: text })
                        }
                      />
                    )}
                    <Pagination
                      firstItem={null}
                      lastItem={null}
                      activePage={activePage}
                      totalPages={totalPages}
                      onPageChange={(event, data) => {
                        this.setState({
                          selected: -1,
                          activePage: Math.ceil(data.activePage),
                        });
                      }}
                    />
                    <Dropdown
                      compact
                      style={{ width: '100px', marginLeft: '10px' }}
                      placeholder="Page Size"
                      value={maxRows}
                      selection
                      upward
                      options={opt}
                      onChange={(e, d) => {
                        this.setState({
                          selected: -1,
                          activePage: 1,
                          maxRows: d.value,
                        });
                      }}
                    />
                    <div style={{ display: 'inline', marginLeft: '10px' }}>
                      Total:{rows ? rows.length : '-'}
                    </div>
                  </div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
        {menuRows}
      </div>
    );
  }
}

export default DataTable;
