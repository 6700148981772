import React, { Component } from 'react';
import { Button, Form, Message, Icon, Image } from 'semantic-ui-react';
import MediaQuery from 'react-responsive';

import { withRouter } from 'react-router-dom';

import styles from './Login.module.scss';

import RestAPI from '../../../RestAPI';
import StateMachine from '../../../services/StateMachine';
import { includesOneOf } from '../../../helpers/scopecheck';

const iconError = 'exclamation triangle';
const iconCheck = 'circle notched';
const iconOk = 'check';

const maxWidth = 500;

const ViewForm = (props) => (
  <div>
    <h1 style={{ color: 'white' }}>Playit Login</h1>
    <Form size={props.size}>
      <Form.Input
        fluid
        icon="user"
        iconPosition="left"
        placeholder="Login-Name"
        onChange={(d, e) => props.onChange('name', e.value)}
      />
      <Form.Input
        fluid
        icon="lock"
        iconPosition="left"
        placeholder="Passwort"
        type="password"
        onChange={(d, e) => props.onChange('passwd', e.value)}
      />
      <Button
        size={props.size}
        color={'blue'}
        disabled={!props.active}
        fluid
        onClick={() => props.onLogin()}
      >
        Login
      </Button>
      <Message
        hidden={props.message === ''}
        icon
        negative={props.icon === iconError}
        positive={props.icon === iconOk}
      >
        <Icon name={props.icon} loading={props.icon === iconCheck} />
        <Message.Content>
          <Message.Header>{props.message}</Message.Header>
        </Message.Content>
      </Message>
    </Form>
  </div>
);

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      passwd: '',
      message: '',
      showIcon: false,
      showMessage: '',
      openHelp: false,
      buttonactive: true,
    };
  }

  onChange(type, data) {
    switch (type) {
      case 'name':
        this.setState({ name: data });
        break;
      case 'passwd':
        this.setState({ passwd: data });
        break;
      default:
        break;
    }
  }

  onLogin() {
    this.setState({
      showIcon: iconCheck,
      buttonactive: false,
      showMessage: 'Überprüfen',
    });
    RestAPI.login(this.state.name, this.state.passwd)
      .then((res) => {
        if (res && res.ok) {
          this.setState({ showMessage: 'ok', showIcon: iconOk });
          if (includesOneOf(res.scope, ['admin', 'root'])) {
            console.log('## send BACKEND');
            console.log(StateMachine._currentState);
            StateMachine.send('BACKEND', { login: res });
            return;
          } else if (includesOneOf(res.scope, ['station'])) {
            StateMachine.send('GAME_LOAD', { login: res });
            return;
          }
        }
        console.error('invalid name or password');
        this.setState({
          showMessage: 'Ungültige Zugangsdaten',
          showIcon: iconError,
          buttonactive: true,
        });
      })
      .catch((err) => {
        this.setState({ showProgress: false });
        console.error(err);
        this.setState({
          showMessage: 'Verbindungsfehler',
          showIcon: iconError,
          buttonactive: true,
        });
      });
  }

  render() {
    return (
      <div className={styles.main}>
        <div className={styles.flex_container}>
          <div className={styles.flex_item1}>
            <div className={styles.image}>
              <Image
                src={process.env.PUBLIC_URL + '/assets/felix_whatsup_small.png'}
              />
            </div>
          </div>
          <div className={styles.flex_item2}>
            <div className={styles.loginform}>
              <MediaQuery minDeviceWidth={maxWidth}>
                <ViewForm
                  onChange={(type, data) => {
                    this.onChange(type, data);
                  }}
                  onLogin={() => {
                    this.onLogin();
                  }}
                  message={this.state.showMessage}
                  icon={this.state.showIcon}
                  active={this.state.buttonactive}
                />
              </MediaQuery>
              <MediaQuery maxDeviceWidth={maxWidth - 1}>
                <ViewForm
                  size={'mini'}
                  onChange={(type, data) => {
                    this.onChange(type, data);
                  }}
                  onLogin={() => {
                    this.onLogin();
                  }}
                  message={this.state.showMessage}
                  icon={this.state.showIcon}
                  active={this.state.buttonactive}
                />
              </MediaQuery>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginForm);
