import React from 'react';
import { Message, Progress } from 'semantic-ui-react';
import styles from './ProgressBar.module.scss';

const ProgressBar = (props) => {
  return (
    <div className={styles.host}>
      <Message>
        <h2>Loading</h2>
        <Progress percent={props.value} color={'green'} active></Progress>
      </Message>
    </div>
  );
};
export default ProgressBar;
