const initialState = {
  centers: [],
  center: null,
};

const reducerCenter = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CENTERS':
      return {
        ...state,
        centers: action.centers,
      };
    case 'SET_CENTER':
      return {
        ...state,
        center: action.center,
      };
    default:
      return state;
  }
};

export default reducerCenter;
