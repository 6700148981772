import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Message, Button } from 'semantic-ui-react';
import EditLine from '../../../1_atom/EditLine/EditLine';
import Password from '../../../1_atom/Password/Password';

import { connect } from 'react-redux';
import store from '../../../../redux/store';

import styles from './User.module.scss';

import RestAPI from '../../../../RestAPI.js';
import { actionSetUser } from '../../../../redux/actions/actionUser.js';
const clone = require('clone');

class User extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      passwd: null,
      apply: false,
    };

    RestAPI.users();
  }

  onApply() {
    if (!this.props.user) {
      return false;
    }

    if (this.props.users.find((u) => u.name === this.state.name)) {
      this.setState({ invalid: true });
      return;
    }

    let user = {
      ...this.props.user,
    };
    user.name = this.state.name !== '' ? this.state.name : this.props.user.name;

    if (this.state.passwd) user.passwd = this.state.passwd;

    store.dispatch(actionSetUser(user));

    this.setState({ apply: false });
    RestAPI.updateUser(user).then((result) => {
      RestAPI.users();
      this.props.history.push('/admin/users');
      this.setState({ apply: false });
    });
  }

  render() {
    const { user, center, users } = this.props;
    const { apply, invalid } = this.state;

    return (
      user && (
        <div className={styles.main}>
          <Message>
            <Message.Header>Account</Message.Header>
            <EditLine
              label="Name:"
              text={user.name}
              onChanged={(text) => {
                this.setState({ name: text, apply: true, invalid: false });
              }}
            />
            {invalid && <div style={{ color: 'red' }}>name exists</div>}
            <Password
              onChanged={(text) => {
                this.setState({ passwd: text, apply: true });
              }}
            />
          </Message>
          {apply && (
            <Button
              floated="left"
              color={'green'}
              onClick={() => this.onApply()}
            >
              Apply
            </Button>
          )}
        </div>
      )
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
User = connect(
  (state) => ({
    user: state.user.user,
    users: state.user.users,
    center: state.center.center,
  }),
  {}
)(User);

export default withRouter(User);
