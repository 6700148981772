export const actionSetMedia = (media) => ({
  type: 'SET_MEDIA',
  media,
});

export const actionAddMedia = (media) => ({
  type: 'ADD_MEDIA',
  media,
});

export const actionUpdateMedia = (uuid, update) => ({
  type: 'UPDATE_MEDIA',
  uuid,
  update,
});

export const actionRemoveMedia = (media) => ({
  type: 'REMOVE_MEDIA',
  media,
});
