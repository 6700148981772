

export const arrayDropDown = (entries, empty) => {

	let array = [];

	if (entries) {
		array.push(...entries.map(entry => {
			return (
				{
					key: entry.uuid,
					text: entry.name,
					value: entry.uuid
				}
			)
		}))
	}
	sortByTextKey(array);

	if (empty)
		array.unshift(
		{
			key: empty ? empty : 'not set',
			text: empty ? empty : 'not set',
			value: null,
		});


	return array;
}


export const sortByTextKey = (array) => {
	array.sort( (a,b) => sortCompare(a,b));
	return array;
}

export const sortCompare = (a, b) => {
	if (a.text < b.text)
		return -1;
	if (a.text > b.text)
		return 1;
	return 0;
}
