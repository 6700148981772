import React, { useState } from 'react';
import Card from '../../1_atom/Card/Card';
import Cursor from '../../1_atom/Cursor/Cursor';
import useDimensions from 'react-cool-dimensions';
import { getMediaURL } from '../../../helpers/media';
import { Motion, spring } from 'react-motion';
import styles from './PlayGround.module.scss';

let gPosLastX = 0;
let gPosX = 0;
let gPosLastY = 0;
let gPosY = 0;
let gHitCard = null;
let gLastMode = null;

/*
  0 1 2 3 
  4 5 6 7
  8 9 10 11
  12 13 14 15
*/

let gIndex = 0;
let gTrigger = false;

const padding = 100;

const audio = new Audio(process.env.PUBLIC_URL + '/assets/sounds/turn.mp3');

const PlayGround = (props) => {
  const {
    space,
    mouseData,
    onCheck,
    onFinished,
    settings,
    media,
    center,
    price,
    cursorMode,
  } = props;

  const { observe, width, height } = useDimensions({
    onResize: ({ height, width }) => {
      gHitCard = null;
      gLastMode = null;

      // Triggered whenever the size of the target is changed...

      // Calc card positions and size
      const cardHeight = height / row;
      const cardWidth = cardHeight;
      const renderCards = finalizedCards.map((entry, index) => {
        const indexRow = Math.floor(index / col);
        const indexCol = index % col;
        const x = indexCol * (cardWidth + space);
        const y = indexRow * (cardHeight + space);
        entry.x = x;
        entry.y = y;
        entry.width = cardWidth;
        entry.height = cardHeight;
        entry.centerPosition = {
          x: x + cardWidth / 2,
          y: y + cardHeight / 2,
        };
        return entry;
      });
      setFinalizedCards(renderCards);
    },
  });

  const [row, setRow] = useState(4);
  const [col, setCol] = useState(4);
  const [openedCards, setOpenedCards] = useState([]);
  const [animationID, setAnimationID] = useState(null);
  const [finalizedCards, setFinalizedCards] = useState(() => {
    if (settings) {
      setCol(settings.col);
      setRow(settings.row);
    }

    // calc num cards. Use default row and col of 6x6=36
    const numCards = settings.row * settings.col;

    // check if num cards are even
    if (numCards % 2 !== 0) {
      console.error('Error: Card are not equal');
    }

    // build array with pairs

    const pairs = settings.mode === 'pairs' ? settings.pairs : settings.combis;

    const numPossibleCards = pairs.length;

    const numPairs = numCards / 2;

    // create random index array
    let idArray = [];
    while (idArray.length < numPairs) {
      const r = Math.floor(Math.random() * numPossibleCards);
      if (idArray.indexOf(r) === -1) idArray.push(r);
    }

    let cardsIdArray = [];
    for (let i = 0; i < numPairs; i++) {
      const id = idArray[i];
      cardsIdArray.push({
        ...pairs[id],
        index: 0,
      });
      cardsIdArray.push({
        ...pairs[id],
        index: settings.mode === 'pairs' ? 0 : 1,
      });
    }

    if (
      price &&
      center &&
      settings &&
      settings.winners &&
      settings.winners[0]
    ) {
      cardsIdArray[0] = {
        ...settings.winners[0],
        index: 0,
      };

      if (settings.winners[1]) {
        cardsIdArray[0] = {
          ...settings.winners[1],
          index: 1,
        };
      } else {
        cardsIdArray[1] = {
          ...settings.winners[0],
          index: 0,
        };
      }
    }

    const shuffle = (array) => {
      array.forEach((el, index, collection) => {
        const randomNumer = Math.floor(Math.random() * array.length);
        [collection[randomNumer], collection[index]] = [
          collection[index],
          collection[randomNumer],
        ];
      });
      return array;
    };
    if (process.env.NODE_ENV === 'production') {
      shuffle(cardsIdArray);
    }

    // create cards
    const finalizedCards = cardsIdArray.map((pair, index) => {
      const backgroundColor = '#f4f5f7';
      const transform = '';
      const media_uuid = pair.media_uuids[pair.index];
      const mediaObejct =
        media && media.find((entry) => entry.uuid === media_uuid);

      let url = '';
      if (pair.default_images)
        url = process.env.PUBLIC_URL + '/' + pair.default_images[pair.index];
      if (mediaObejct) url = getMediaURL(mediaObejct);

      return {
        uuid: pair.uuid,
        index, // index of the card in the card field
        close: true,
        complete: false,
        backgroundColor,
        transform,
        url: url,
      };
    });
    return finalizedCards;
  });

  const handleClick = (card) => {
    if (animationID) return;

    audio.play();

    if (openedCards.length === 2) {
      setAnimationID(
        setTimeout(() => {
          setAnimationID(null);
          check();
        }, 1750)
      );
    } else if (finalizedCards[card.index] && finalizedCards[card.index].close) {
      finalizedCards[card.index].close = false;
      // add clicked card to opened cards
      openedCards.push(card);
      setOpenedCards([...openedCards]);

      setFinalizedCards([...finalizedCards]);

      if (openedCards.length === 2) {
        setAnimationID(
          setTimeout(() => {
            setAnimationID(null);
            check();
          }, 1750)
        );
      }
    }
  };
  const check = () => {
    const checkResult = openedCards[0].uuid === openedCards[1].uuid;

    if (checkResult) {
      // found pair, yeeah!
      finalizedCards[openedCards[0].index].complete = true;
      finalizedCards[openedCards[1].index].complete = true;
    } else {
      // no luck. Close it
      finalizedCards[openedCards[0].index].close = true;
      finalizedCards[openedCards[1].index].close = true;
    }

    // set changed cards
    setFinalizedCards(finalizedCards);

    // clear opened card array
    setOpenedCards([]);

    if (!finalizedCards.find((entry) => !entry.complete)) {
      // finished

      if (onFinished) {
        if (price && settings.winners) {
          onFinished(true);
        } else {
          onFinished(false);
        }
      }
    } else {
      if (onCheck) onCheck(checkResult);
    }
  };

  // check border limitation mouseData
  let x = 0;
  let y = 0;

  const checkIfInCard = (card) => {
    return (
      x > card.x &&
      x < card.x + card.width &&
      y > card.y &&
      y < card.y + card.height
    );
  };

  const factor = 100;

  const cornerRight = width + padding;
  const cornerLeft = 0 - padding;
  const cornerTop = 0 - padding;
  const cornerBottom = height + padding;

  if (mouseData) {
    // normal smooth mode
    if (
      cursorMode === 'smooth' &&
      (mouseData.mode === 'move' || mouseData.mode === 'end')
    ) {
      x = mouseData.x * factor - gPosLastX;
      if (x > cornerRight) {
        if (mouseData.mode === 'end') gPosLastX += x - cornerRight;
        x = cornerRight;
      } else if (x < cornerLeft) {
        if (mouseData.mode === 'end') gPosLastX += x - cornerLeft;
        x = cornerLeft;
      }

      y = mouseData.y * factor - gPosLastY;
      if (y > cornerBottom) {
        if (mouseData.mode === 'end') gPosLastY += y - cornerBottom;
        y = cornerBottom;
      } else if (y < cornerTop) {
        if (mouseData.mode === 'end') gPosLastY += y - cornerTop;
        y = cornerTop;
      }

      // over all cards
      let pressure = 1;
      finalizedCards.forEach((card) => {
        if (checkIfInCard(card) && pressure > 0.4) {
          gHitCard = card;
          card.backgroundColor = 'rgb(128, 0, 128,0.5)';
        } else card.backgroundColor = '#f4f5f7';
      });
      gPosX = x;
      gPosY = y;
    }

    // excel mode
    if (cursorMode === 'excel') {
      if (mouseData.mode === 'end') {
        gTrigger = false;
      } else if (mouseData.mode === 'excel' && !gTrigger) {
        gTrigger = true;
        // calc x y from gIndex
        let y = Math.floor(gIndex / col);
        let x = gIndex % col;
        switch (mouseData.direction) {
          case 0:
            x += 1;
            break;
          case 1:
            x += 1;
            y -= 1;
            break;
          case 2:
            y -= 1;
            break;
          case 3:
            x -= 1;
            y -= 1;
            break;
          case 4:
            x -= 1;
            break;
          case 5:
            x -= 1;
            y += 1;
            break;
          case 6:
            y += 1;
            break;
          case 7:
            x += 1;
            y += 1;
            break;
          default:
            break;
        }
        if (x >= col) x = col - 1;
        if (x < 0) x = 0;
        if (y >= row) y = row - 1;
        if (y < 0) y = 0;

        gIndex = y * col + x;
        gHitCard = finalizedCards.find((card) => card.index === gIndex);

        //        console.log('#', mouseData.direction, x, y, gHitCard.index);

        gPosX = gHitCard.centerPosition.x;
        gPosY = gHitCard.centerPosition.y;
      }
    }

    if (gHitCard && mouseData.mode === 'click' && gLastMode === 'end') {
      handleClick(gHitCard);
    }

    gLastMode = mouseData.mode;
  }

  return (
    <div className={styles.main} ref={observe}>
      {finalizedCards.map((card) => {
        return (
          <Card
            key={card.index}
            card={card}
            onClick={() => {
              // only for debugging
              handleClick(card);
            }}
            url={card.url}
          />
        );
      })}
      <Motion
        style={{
          x: spring(gPosX),
          y: spring(gPosY),
        }}
      >
        {(interpolatingStyle) => {
          return (
            <Cursor position={[interpolatingStyle.x, interpolatingStyle.y]} />
          );
        }}
      </Motion>
    </div>
  );
};

export default PlayGround;
