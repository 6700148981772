let _vm = null;
let _cb = {};

const _lang = 'de';

let _animations = {
  attract: {
    start: 1,
    end: 20,
    current: 0,
  },
  correct: {
    start: 1,
    end: 16,
    current: 0,
  },
  wrong: {
    start: 1,
    end: 15,
    current: 0,
  },
  winner: {
    start: 1,
    end: 1,
    current: 0,
    name: 'hightscore_winner',
  },
  looser: {
    start: 1,
    end: 1,
    current: 0,
    name: 'hightscore_looser',
  },
  countdown: {
    start: 1,
    end: 1,
    current: 0,
    name: 'countdown',
  },
};

const _sequences = {
  attract: 'attract_seq',
  passive: 'passive_seq',
  joystick: 'joystick_seq',
  joystick_combi: 'joystick_combipair_seq',
  countdown: 'countdown_seq',
  gaming: 'gaming_seq',
  highscore_looser: 'highscore_looser_anyways_seq',
  highscore_looser_nowinpair: 'highscore_looser_nowinpair_seq',
  highscore_winner: 'highscore_winner_seq',

  charging: 'charging_seq',
};

const VuppetMasterHelper = {
  init() {
    let progressAnimations = 0;
    let progressProject = 0;
    const callProgress = () => {
      const value = ((progressProject + progressAnimations) / 2) * 100;
      if (_cb['project.progress']) _cb['project.progress'](value);

      if (progressAnimations + progressProject === 2)
        if (_cb['complete']) _cb['complete']();
    };

    _vm = new window.VM.Vuppetmaster({
      app: 'playit2',
    });

    _vm.on('complete', async () => {
      if (process.env.NODE_ENV === 'production') {
        await _vm.core.preloadAll((value) => {
          progressAnimations = value;
          callProgress();
        });
      } else {
        progressAnimations = 1;
        callProgress();
      }

      _vm.core.playProject();
    });

    _vm.on('project.progress', (data) => {
      progressProject = data.value;
      callProgress();
    });
    _vm.loadProject(null, {
      start: false,
    });
    return _vm;
  },

  destroy() {
    if (_vm) _vm.destroy();
  },

  initAvatar(url) {
    // instantiate a loader
    const loader = new window.VM.TextureLoader();
    loader.setCrossOrigin('use-credentials');

    // load a resource
    loader.load(
      // resource URL
      url,

      // onLoad callback
      (texture) => {
        texture.wrapS = window.VM.RepeatWrapping;
        texture.wrapT = window.VM.RepeatWrapping;
        texture.flipY = false;
        const felix = _vm.models.getByName('felix');
        felix.setTexture('shirtlogo', 'shirtlogo', texture);
      },

      // onProgress callback currently not supported
      undefined,

      // onError callback
      function (err) {
        console.error('An error happened.');
      }
    );
  },

  async playAnimationByName(name) {
    let animation = _animations[name];
    animation.current++;
    if (animation.current > animation.end) animation.current = animation.start;
    if (animation.name) name = animation.name;
    return _vm.core.playAnimationByName(
      `${name}_${_lang}_${animation.current}`
    );
  },

  async playSequenceByName(name) {
    const seq = _sequences[name];
    if (seq) return _vm.core.playSequenceByName(seq);
  },

  on(name, cb) {
    _cb[name] = cb;
  },
};

export default VuppetMasterHelper;
