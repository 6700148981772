import store from './redux/store';
import { actionSetLogin } from './redux/actions/actionLogin';
import { actionSetUser, actionSetUsers } from './redux/actions/actionUser';
import {
  actionSetCenter,
  actionSetCenters,
} from './redux/actions/actionCenter';
import { actionSetGames } from './redux/actions/actionGames';
import {
  actionAddMedia,
  actionRemoveMedia,
  actionSetMedia,
  actionUpdateMedia,
} from './redux/actions/actionMedia';
import { actionSetSessions } from './redux/actions/actionSession';

// Init: check backend
var API_ROOT = null;
var API_VM = null;
var API_BASE = null;
var API_BASE_URL = null;
var API_SOCKET_IO = null;

let server = 'https://playit-game.com';
let local = 'http://localhost';

export const getVM = () => {
  return API_VM;
};

export const getRoot = () => {
  return API_ROOT;
};

export const getBase = () => {
  return API_BASE;
};

export const getBaseUrl = () => {
  return API_BASE_URL;
};

export const getSocketIO = () => {
  return API_SOCKET_IO;
};

export const init = async () => {
  const hostname = window && window.location && window.location.hostname;
  console.log('init hostname ', hostname);

  let protocol = 'http';
  if (process.env.NODE_ENV === 'production') {
    protocol = 'https';
  }

  if (process.env.NODE_ENV === 'production') {
    API_VM = 'https://vuppetmaster.de/vmapi/v4.playit2';
    API_SOCKET_IO = hostname;
  } else {
    API_VM = 'http://' + hostname + ':9443/vmapi/v4.local';
    API_SOCKET_IO = protocol + '://localhost:11443';

    // check if local vuppetmaster exits
    const res = await fetch(API_VM + '/ping', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        console.log(response);
        return { ok: response.ok };
      })
      .catch((e) => {
        return { ok: false, error: e.message };
      });
    if (!res || (res && !res.ok)) {
      API_VM = 'https://vuppetmaster.de/vmapi/v4';
    }
  }

  if (
    process.env.NODE_ENV === 'production' ||
    hostname.endsWith('playit-game.com')
  ) {
    API_ROOT = server + '/api_playit2';
    API_BASE = server;
    API_BASE_URL = server;
  } else {
    API_ROOT = local + ':11443/api_playit2';
    API_BASE = local;
    API_BASE_URL = window.location.origin;
  }

  console.log('API_ROOT = ', API_ROOT);
  console.log('API_BASE = ', API_BASE);
  console.log('API_VM = ', API_VM);
};

const post = async (url, data) => {
  console.log('post ', url);

  return await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify(data),
    mode: 'cors',
  })
    .then((response) => {
      if (!response) return null;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      } else {
        return response.text().then((text) => ({
          ok: false,
          status: response.status,
          error: text,
        }));
      }
    })
    .catch((e) => {
      return { ok: false, error: e.message };
    });
};

const get = async (url, queryData) => {
  console.log('get ', url);

  const query = queryData
    ? '?' +
      Object.keys(queryData)
        .map(
          (k) => encodeURIComponent(k) + '=' + encodeURIComponent(queryData[k])
        )
        .join('&')
    : '';
  return await fetch(url + query, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    mode: 'cors',
  })
    .then((response) => {
      if (!response) return null;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        return response.json();
      } else {
        return response.text().then((text) => ({ ok: false, error: text }));
      }
    })
    .catch((e) => {
      return { ok: false, error: e.message };
    });
};

const postForm = async (url, data) => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
    },
    credentials: 'include',
    body: data,
    mode: 'cors',
  }).then((response) => {
    if (!response) return null;
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    } else {
      return response
        .text()
        .then((text) => ({ ok: false, status: response.status, error: text }));
    }
  });
};

export const graphQL = async (query) => {
  let result = post(API_ROOT + '/graphql', { query });
  return result;
};

export const toGraphQL = (object, addBrackets = true) => {
  if (!object) {
    return object;
  }

  const toString = (value) => {
    switch (typeof value) {
      case 'string':
        return JSON.stringify(value);
      case 'number':
        return value + '';
      case 'object':
        return toGraphQL(value);
      default:
        return value;
    }
  };

  if (object.length !== undefined) {
    const values = object.map((value) => toString(value)).join(', ');
    return addBrackets ? `[${values}]` : values;
  } else {
    const values = Object.keys(object)
      .filter((key) => object[key] !== undefined)
      .map((key) => key + ':' + toString(object[key]))
      .join(', ');

    return addBrackets ? `{${values}}` : values;
  }
};

// AUTHENTICATION

export const login = async (name, passwd) => {
  const result = await post(API_ROOT + '/auth/login', {
    name,
    passwd,
  });
  if (result && result.ok) {
    store.dispatch(actionSetLogin(result));
    return result;
  }
  return null;
};

export const logout = async () => {
  const result = await get(API_ROOT + '/auth/logout');
  if (result && result.ok) {
    store.dispatch(actionSetLogin(null));
  }
  return result;
};

export const authStatus = async () => {
  const result = await get(API_ROOT + '/auth/status');
  if (result && result.ok) {
    store.dispatch(actionSetLogin(result));
    return result;
  }
  return null;
};

// USER
export const user = async (uuid) => {
  const result = await graphQL(
    `{users(filter: {uuid: {EQ: "${uuid}"}}) { name, uuid, center_uuid, scope, active, game_uuid, game_settings, modified, created } }`
  );
  const user =
    result && result.data && result.data.users && result.data.users[0];
  if (user) {
    store.dispatch(actionSetUser(user));
  }
  return user;
};

export const users = async () => {
  const result = await graphQL(
    `{users { name, uuid, center_uuid, game_uuid, game_settings, scope, active, modified, created } }`
  );
  const users = result && result.data && result.data.users;
  if (users) {
    store.dispatch(actionSetUsers(users));
  }
  return users;
};

export const createUser = async (user) => {
  return graphQL(`mutation { users_create(${toGraphQL(user, false)}) { ok } }`);
};

export const updateUser = async (user) => {
  return graphQL(
    `mutation { users_update(filter: {uuid: {EQ: "${
      user.uuid
    }"}}, update: {set: ${toGraphQL(user)} }) { ok } }`
  );
};

export const deleteUser = async (user) => {
  return graphQL(
    `mutation { users_delete(filter: {uuid: {EQ: "${user.uuid}"}}) { ok } }`
  );
};

// GAMES
export const games = async () => {
  const result = await graphQL(`{games { name, uuid, type, settings } }`);
  const games = result && result.data && result.data.games;
  if (games) {
    store.dispatch(actionSetGames(games));
  }
  return games;
};

// CENTERS
export const centers = async () => {
  const result = await graphQL(
    `{centers { name, uuid, image_media_uuid, shirt_media_uuid, active } }`
  );
  const centers = result && result.data && result.data.centers;
  if (centers) {
    store.dispatch(actionSetCenters(centers));
  }
  return centers;
};

export const center = async (uuid) => {
  const result = await graphQL(
    `{centers(filter: {uuid: {EQ: "${uuid}"}}) { name, uuid, image_media_uuid, shirt_media_uuid, active } }`
  );
  const center = result && result.data && result.data.centers[0];
  if (center) {
    store.dispatch(actionSetCenter(center));
  }
  return center;
};

export const createCenter = async (data) => {
  const result = await graphQL(
    `mutation { centers_create(${toGraphQL(data, false)}) { ok, uuid } }`
  );
  return result && result.data && result.data.centers_create;
};

export const updateCenter = async (center) => {
  return graphQL(
    `mutation { centers_update(filter: {uuid: {EQ: "${
      center.uuid
    }"}}, update: {set: ${toGraphQL(center)} }) { ok } }`
  );
};

export const deleteCenter = async (center) => {
  return graphQL(
    `mutation { centers_delete(filter: {uuid: {EQ: "${center.uuid}"}}) { ok } }`
  );
};

// MEDIA

export const media = async (center_uuid) => {
  const result = await graphQL(
    `{media (filter: {center_uuid: {EQ: "${center_uuid}"}}) { uuid, name, center_uuid, width, height, filename, type, created } }`
  );
  if (result && result.data && result.data.media) {
    store.dispatch(actionSetMedia(result.data && result.data.media));
  }
  return result;
};

export const updateMedia = async (media) => {
  return graphQL(
    `mutation { media_update(filter: {uuid: {EQ: "${
      media.uuid
    }"}}, update: {set: ${toGraphQL(media)} }) { ok } }`
  );
};

export const deleteMedia = async (media) => {
  const result = await graphQL(
    `mutation { media_delete(filter: {uuid: {EQ: "${media.uuid}"}}) { ok } }`
  );
  if (
    result &&
    result.data &&
    result.data.media_delete &&
    result.data.media_delete.ok
  ) {
    store.dispatch(actionRemoveMedia(media.uuid));
  }
  return result;
};

export const uploadMedia = async (file, center_uuid) => {
  let form = new FormData();
  form.append('center_uuid', center_uuid);
  form.append('file', file);
  const result = await postForm(API_ROOT + '/media/upload', form);
  if (result && result.ok && result.data) {
    store.dispatch(actionAddMedia(result.data));
  }
  return result;
};

export const replaceMedia = async (uuid, center_uuid, file) => {
  let form = new FormData();
  form.append('uuid', uuid);
  form.append('center_uuid', center_uuid);
  form.append('file', file);
  const result = await postForm(API_ROOT + '/media/replace', form);
  if (result && result.ok && result.data) {
    store.dispatch(actionUpdateMedia(uuid, result.data));
  }
  return result;
};

// SESSION
export const createSession = async (session) => {
  const result = await graphQL(
    `mutation { sessions_create(${toGraphQL(session, false)}) { ok, uuid  } }`
  );
  if (result && result.data && result.data.sessions_create) {
    return {
      ...session,
      uuid: result.data.sessions_create.uuid,
    };
  }
  return null;
};

export const registerSession = async (uuid) => {
  const result = await get(API_ROOT + '/session/registerSession', {
    uuid,
  });
  return result;
};

export const playSession = async (uuid) => {
  const result = await get(API_ROOT + '/session/playSession', {
    uuid,
  });
  return result;
};

export const endSession = async (data) => {
  const result = await get(API_ROOT + '/session/end', data);
  return result;
};

export const sessions = async () => {
  const result = await graphQL(
    `{sessions { uuid, center_uuid, code, created, started, ended, text, prices, finished } }`
  );
  if (result && result.data && result.data.sessions) {
    store.dispatch(actionSetSessions(result.data.sessions));
    return result.data.sessions;
  }
  return result;
};

export const sessionsWinnerByDate = async (user_uuid, date) => {
  const result = await get(API_ROOT + '/session/sessionsWinnerByDate', {
    user_uuid,
    date,
  });
  if (result && result.data && result.data.sessions) {
    store.dispatch(actionSetSessions(result.data.sessions));
    return result.data.sessions;
  }
  return result;
};

export const isPriceGame = async (user_uuid, date) => {
  const result = await get(API_ROOT + '/session/isPriceGame', {
    user_uuid,
  });
  return result;
};

export const updateSession = async (session) => {
  return graphQL(
    `mutation { sessions_update(filter: {uuid: {EQ: "${
      session.uuid
    }"}}, update: {set: ${toGraphQL(session)} }) { ok } }`
  );
};

export default {
  post,
  get,
  graphQL,

  init,
  login,
  logout,
  authStatus,

  createSession,
  registerSession,
  playSession,
  endSession,
  sessions,
  sessionsWinnerByDate,
  isPriceGame,
  updateSession,

  user,
  users,
  createUser,
  updateUser,
  deleteUser,

  centers,
  center,
  createCenter,
  updateCenter,
  replaceMedia,
  deleteCenter,

  games,

  media,
  updateMedia,
  uploadMedia,
  deleteMedia,

  getRoot,
  getVM,
  getBase,
  getBaseUrl,
  getSocketIO,
};
