import { io } from 'socket.io-client';
import { createMachine, interpret, assign } from 'vmxstate/lib/index';
import RestAPI from '../RestAPI.js';
import store from '../redux/store';
import { actionSetXState, actionSetApiBase } from '../redux/actions/actionMain';
import { includesOneOf } from '../helpers/scopecheck';
import { v4 as uuidv4 } from 'uuid';

const StateMachine = {
  _socketio: null,
  _maschine: null,
  _service: null,
  _vuppetmaster: null,
  _store: null,
  _history: null,
  _events: [],
  _currentState: null,

  _player: 'CefSharp' in window,

  init(history, timeoutStart) {
    this._history = history;
    this._startDuration = timeoutStart;
    this._maschine = createMachine({
      id: 'playit2',
      type: 'parallel',
      context: {
        login: null,
        settings: null,
        sleep: false,
      },
      states: {
        set: {
          on: {
            SLEEP: {
              actions: [
                assign({
                  sleep: (_, event) => {
                    return event.flag;
                  },
                }),
              ],
            },
          },
        },
        game: {
          initial: 'init',
          states: {
            init: {
              on: {},
              invoke: {
                id: 'init',
                src: (context, event) =>
                  RestAPI.init().then(() => {
                    store.dispatch(actionSetApiBase(RestAPI.getBase()));
                  }),
                onDone: {
                  target: 'checkAuth',
                },
                onError: 'fatalError',
              },
            },
            checkAuth: {
              invoke: {
                id: 'checkAuth',
                src: (context, event) =>
                  RestAPI.authStatus().then((result) => {
                    return result && result.ok && result.scope ? result : null;
                  }),
                onDone: [
                  {
                    target: 'login',
                    cond: (context, event) => {
                      return event.data === null;
                    },
                  },
                  {
                    target: 'backend',
                    actions: assign({
                      login: (_, event) => event.data,
                    }),
                    cond: (context, event) => {
                      return includesOneOf(event.data.scope, ['admin', 'root']);
                    },
                  },
                  {
                    target: 'game_init',
                    actions: assign({
                      login: (_, event) => event.data,
                    }),
                    cond: (context, event) => {
                      return includesOneOf(event.data.scope, ['station']);
                    },
                  },
                ],
                onError: 'fatalError',
              },
            },
            login: {
              on: {
                GAME_LOAD: {
                  target: 'game_init',
                  actions: assign({
                    login: (_, event) => {
                      return event.login;
                    },
                  }),
                },
                BACKEND: {
                  target: 'backend',
                  actions: assign({
                    login: (_, event) => {
                      return event.login;
                    },
                  }),
                },
              },
              entry: () => {
                this._history.push('/login');
              },
            },

            game_init: {
              on: {
                LOGOUT: 'logout',
              },

              invoke: {
                id: 'game_init',
                src: (context, event) =>
                  Promise.all([
                    RestAPI.user(context.login.uuid).then((user) => {
                      return user;
                    }),
                    RestAPI.center(context.login.center_uuid),
                  ]),

                onDone: [
                  {
                    target: 'game_load',
                    actions: [
                      assign({
                        settings: (_, event) => {
                          return event.data[0].game_settings['paarsuche'];
                        },
                      }),
                    ],
                  },
                ],
                onError: 'fatalError',
              },
            },
            game_load: {
              on: {
                GAME_LOADED: [
                  {
                    target: 'game_intro',
                    cond: (context, event) => {
                      return this._player;
                    },
                  },
                  {
                    target: 'game_press_start',
                    cond: (context, event) => {
                      return !this._player;
                    },
                  },
                  {
                    target: 'game_intro',
                  },
                ],
                LOGOUT: 'logout',
              },
              entry: (context) => {
                this._history.push('/game');
              },
            },
            game_press_start: {
              on: {
                PRESS: 'game_intro',
                LOGOUT: 'logout',
              },
            },
            game_refresh: {
              after: [
                {
                  delay: () => {
                    return 1;
                  },
                  target: 'game_intro',
                },
              ],
            },
            game_intro: {
              on: {
                LOGOUT: 'logout',
                GAME_CREATE_SESSION: 'game_create_session',
              },
            },
            game_create_session_trigger: {
              after: [
                {
                  delay: () => {
                    return 1;
                  },
                  target: 'game_create_session',
                },
              ],
            },
            game_create_session: {
              on: {
                LOGOUT: 'logout',
                GAME_WAIT: 'game_wait',
                GAME_CREATE_SESSION: 'game_create_session_trigger',
              },
            },
            game_wait: {
              type: 'parallel',
              states: {
                intro: {
                  on: {
                    LOGOUT: '#playit2.game.logout',
                    MOBILE_CONNECTED: '#playit2.game.mobile_connected',
                  },
                  entry: () => {
                    this._history.push('/game');
                  },
                  after: [
                    {
                      delay: () => {
                        return 1000 * 60 * 10; // 10 min
                      },
                      target: '#playit2.game.game_create_session',
                    },
                  ],
                },
                check: {
                  after: [
                    {
                      delay: () => {
                        return 1000;
                      },
                      target: '#playit2.game.sleep',
                      cond: (context, event) => {
                        return context.sleep ? true : false;
                      },
                    },
                    {
                      delay: () => {
                        return 1000;
                      },
                      target: 'check',
                      cond: (context, event) => {
                        return context.sleep ? false : true;
                      },
                    },
                  ],
                },
              },
            },
            mobile_connected: {
              on: {
                MOBILE_START: 'game_countdown',
                MOBILE_REFRESH: 'mobile_connected',
                LOGOUT: 'logout',
              },
              after: [
                {
                  delay: (context, event) => {
                    return this._startDuration * 1000;
                  },
                  target: 'game_intro',
                },
              ],
            },
            game_countdown: {
              on: {
                GAME_START: 'game_play_intro',
                LOGOUT: 'logout',
              },
            },
            game_play_intro: {
              on: {
                GAME_PLAY: 'game_play',
                LOGOUT: 'logout',
              },
            },
            game_play: {
              after: [
                {
                  delay: (context, event) => {
                    return context.settings.duration * 1000;
                  },
                  target: 'game_loose',
                },
              ],
              on: {
                FINISHED: 'game_loose_nocenterpair',
                FINISHED_CENTER: 'game_win',
                LOGOUT: 'logout',
              },
              entry: () => {},
            },
            game_loose: {
              on: {
                LOGOUT: 'logout',
              },
              after: {
                16000: {
                  target: 'game_end',
                },
              },
            },
            game_loose_nocenterpair: {
              on: {
                LOGOUT: 'logout',
              },
              after: {
                22000: {
                  target: 'game_end',
                },
              },
            },
            game_win: {
              on: {
                LOGOUT: 'logout',
              },
              after: {
                18000: {
                  target: 'game_end',
                },
              },
            },
            game_end: {
              on: {
                LOGOUT: 'logout',
              },
              after: {
                1000: {
                  target: 'game_intro',
                },
              },
            },
            sleep: {
              on: { LOGOUT: 'logout' },
              after: [
                {
                  delay: () => {
                    return 1000;
                  },
                  target: 'game_intro',
                  cond: (context, event) => {
                    return context.sleep ? false : true;
                  },
                },
                {
                  delay: () => {
                    return 1000;
                  },
                  target: 'sleep',
                  cond: (context, event) => {
                    return context.sleep ? true : false;
                  },
                },
              ],
            },
            logout: {
              invoke: {
                id: 'logout',
                src: (context, event) =>
                  RestAPI.logout().then((result) => {
                    return result;
                  }),
                onDone: [
                  {
                    target: 'waitlogin',
                  },
                ],
                onError: 'fatalError',
              },
              entry: () => {
                this._history.push('/login');
              },
            },
            waitlogin: {
              after: [
                {
                  delay: () => {
                    return 2000;
                  },
                  target: 'login',
                },
              ],
            },
            backend: {
              entry: (context) => {
                RestAPI.center(context.login.center_uuid).then((center) => {
                  RestAPI.user(context.login.uuid);
                  if (center) RestAPI.media(center.uuid);
                  this._history.push('/admin/settings/' + context.login.uuid);
                });
              },
              on: {
                LOGOUT: 'logout',
              },
            },
            fatalError: {},
          },
        },
      },
    });

    // Machine instance with internal state
    this._service = interpret(this._maschine, {
      uuid: 'playit2',
      io,
      local: false,
    })
      .onTransition((state) => {
        let gameState = state.value.game;
        if (typeof gameState === 'object') {
          gameState = Object.keys(gameState)
            .map((k, v) => k)
            .join('-');
        }

        if (this._currentState !== gameState) {
          this._currentState = gameState;
          console.log('# XState:', gameState);

          store.dispatch(actionSetXState(gameState));
          this._events
            .filter((event) => event.name === gameState)
            .forEach((event) => {
              event.cb();
            });
        }
      })
      .start();
  },

  send(type, data = {}) {
    this._service.send({ type, ...data });
  },

  addEvent(event, cb) {
    const uuid = uuidv4();
    this._events.push({
      name: event,
      cb,
      uuid,
    });
    return uuid;
  },

  releaseEvent(uuid) {
    this._events = this._events.filter((entry) => entry.uuid !== uuid);
  },
};

export default StateMachine;
