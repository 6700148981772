import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Button, Icon, Label, Message } from 'semantic-ui-react';
import Script from 'react-load-script';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Login from './../Login/Login.js';
import Imprint from '../../1_atom/Imprint/Imprint';
import RenderView from '../../1_atom/RenderView/RenderView';
import ProgressBar from '../../1_atom/ProgressBar/ProgressBar';
import Game from '../Game/Game.js';
import styles from './MainPage.module.scss';
import StateMachine from '../../../services/StateMachine';
import { io } from 'socket.io-client';

import RestAPI from '../../../RestAPI.js';
import { cx } from '../../../helpers/classNames.helper';
import store from './../../../redux/store';
import { actionSetXState } from './../../../redux/actions/actionMain';

import PlayGround from '../PlayGround/PlayGround.js';
import ControlCenter from '../../1_atom/ControlCenter/ControlCenter.js';
import BackendMain from '../Backend/BackendMain/BackendMain.js';
import VuppetMasterHelper from '../../../helpers/VuppetMaster.helper.js';
import { includesOneOf } from '../../../helpers/scopecheck';
import { getMediaURL } from '../../../helpers/media.js';
import TimerService from '../../../services/TimerService.js';
import { actionSetLogin } from '../../../redux/actions/actionLogin.js';
import preval from 'preval.macro';

const sbKey = '8YH1R-54RXK-2NF2N-J8RZ8';

let gameTimeout = 90; // in sec
let startTimeout = 60; // in sec
if (process.env.NODE_ENV !== 'production') {
  gameTimeout = 90; // in sec
  startTimeout = 111130; // in sec
}

const maryamFlag = false;

/**** MainPage ***************************************************************/
/*!
 **
 ******************************************************************************/
class MainPage extends Component {
  constructor(props) {
    super(props);

    let title = 'Playit';

    if (process.env.NODE_ENV !== 'production') {
      title += ' ' + process.env.NODE_ENV;
    }

    this.state = {
      progress: 0.001,
      vmReady: false,
      title: title,
      backgroundURL: `${process.env.PUBLIC_URL}/assets/background.jpg`,
      serverConnect: false,
      mouseData: null,
      session_uuid: null,
      session: null,
      showLogout: true,
      showVersion: true,
      loadVM: false,
      motiv: null,
      priceCard: false,
    };
    this._startTimeID = 0;

    this._audioBackground = new Audio(
      process.env.PUBLIC_URL + '/assets/sounds/questionaire_2min.mp3'
    );
  }

  componentDidMount() {
    if (maryamFlag) {
      store.dispatch(actionSetXState('game_play'));
      return;
    }

    if (StateMachine._player) {
      setTimeout(() => {
        this.setState({ showLogout: false });
      }, 15 * 1000);
    }
    setTimeout(() => {
      this.setState({ showVersion: false });
    }, 10 * 1000);

    /********************************* 

      State Machine

    *********************************/
    StateMachine.init(this.props.history, startTimeout);
    StateMachine.addEvent('game_load', async () => {
      this.setState({ loadVM: true });

      // user center valid
      const settings = this.props.user.game_settings;
      const paarsucheSettings = settings['paarsuche'];

      this.setState({
        settings: paarsucheSettings,
        motiv: settings.motiv_uuid,
        cursorMode: settings.cursorMode ? settings.cursorMode : 'smooth',
      });
    });
    StateMachine.addEvent('game_intro', async () => {
      TimerService.init(this.props.user);

      /*
        game_intro
      */

      this._startTimeID = 0;

      StateMachine.send('GAME_CREATE_SESSION');

      await VuppetMasterHelper.playSequenceByName('attract');
    });

    StateMachine.addEvent('game_create_session', async () => {
      /*
        game_create_session
      */
      const login = this.props.login;

      // check if the new game have process
      const result = await RestAPI.isPriceGame(login.uuid);
      const prices = result && result.ok === true && result.prices;
      this.setState({ priceCard: prices });

      const session = await RestAPI.createSession({
        center_uuid: login.center_uuid,
        game_uuid: login.game_uuid,
        user_uuid: login.uuid,
        created: new Date().toISOString(),
        prices,
        finished: false,
        game_settings: this.props.user.game_settings,
      });
      if (session) {
        this.setState({ session_uuid: session.uuid, session });

        this._socketio.emit('registerSession', {
          user_uuid: this.props.login.uuid,
          game_uuid: this.props.login.game_uuid,
          center_uuid: this.props.login.center_uuid,
          session_uuid: session.uuid,
        });

        StateMachine.send('GAME_WAIT');
      } else {
        setTimeout(() => {
          StateMachine.send('GAME_CREATE_SESSION');
        }, 30 * 1000);
      }
    });

    StateMachine.addEvent('game_wait', async () => {
      while (this.props.xstate === 'game_wait') {
        await VuppetMasterHelper.playSequenceByName('passive');
      }
    });

    StateMachine.addEvent('game_countdown', async () => {
      /*
        game_countdown
      */
      await VuppetMasterHelper.playSequenceByName('countdown');
      StateMachine.send('GAME_START');
    });
    StateMachine.addEvent('game_play_intro', async () => {
      /*
        game_play_intro
      */
      await VuppetMasterHelper.playSequenceByName('charging');
      StateMachine.send('GAME_PLAY');
    });

    StateMachine.addEvent('game_play', async () => {
      /*
        game_play
      */

      while (this.props.xstate === 'game_play') {
        await this._audioBackground.play();
      }
      this._audioBackground.pause();
    });
    StateMachine.addEvent('game_loose', async () => {
      /*
        game_loose
      */
      VuppetMasterHelper.playSequenceByName('highscore_looser');

      //      highscore_looser_nowinpair;

      await RestAPI.endSession({
        uuid: this.state.session.uuid,
        win: false,
        finished: false,
      });

      this._socketio.emit(
        's2mStatus',
        { session_uuid: this.state.session_uuid, status: 'loose' },
        (result) => {}
      );
    });
    StateMachine.addEvent('game_loose_nocenterpair', async () => {
      /*
        game_loose
      */
      VuppetMasterHelper.playSequenceByName('highscore_looser_nowinpair');

      await RestAPI.endSession({
        uuid: this.state.session.uuid,
        win: false,
        finished: true,
      });

      this._socketio.emit(
        's2mStatus',
        { session_uuid: this.state.session_uuid, status: 'loose' },
        (result) => {}
      );
    });
    StateMachine.addEvent('game_win', async () => {
      /*
        game_win
      */
      VuppetMasterHelper.playSequenceByName('highscore_winner');

      const result = await RestAPI.endSession({
        uuid: this.state.session.uuid,
        win: true,
        finished: true,
      });

      const code = result.code;

      this._socketio.emit(
        's2mStatus',
        {
          session_uuid: this.state.session_uuid,
          status: 'win',
          data: {
            code,
          },
        },
        (result) => {}
      );
    });
    StateMachine.addEvent('game_end', async () => {
      /*
        game_end
      */
      this._socketio.emit('unregister', {
        session_uuid: this.state.session_uuid,
      });
      this.setState({ session_uuid: null });
    });

    /********************************* 

      Socket IO

      Problem with:failed: Error during WebSocket handshake: Unexpected response code: 400"
      https://newbedev.com/websocket-connection-failed-error-during-websocket-handshake-unexpected-response-code-400

    *********************************/
    this._socketio = io(RestAPI.getSocketIO() + '/station', {
      path: '/api_playit2/socket.io',
      reconnection: true,
      transports: ['websocket'],
    });
    this._socketio.on('connect', () => {
      console.log('connect socket io');
      this.setState({ serverConnect: true });
    });
    this._socketio.on('disconnect', (data) => {
      this.setState({ serverConnect: false });
    });
    this._socketio.on('m2sMouse', (data) => {
      this.setState({
        mouseData: data,
      });
      if (this.props.xstate === 'mobile_connected') {
        if (!this._startTimeID) {
          this._startTimeID = setTimeout(() => {
            this._startTimeID = 0;
            if (this.props.xstate === 'mobile_connected')
              StateMachine.send('MOBILE_REFRESH');
          }, 200);
        }
      }
    });
    this._socketio.on('m2sConnected', (data) => {
      StateMachine.send('MOBILE_CONNECTED');
    });
    this._socketio.on('m2sStart', (data) => {
      StateMachine.send('MOBILE_START');
      this._socketio.emit(
        's2mStatus',
        { session_uuid: this.state.session_uuid, status: 'play' },
        (result) => {}
      );
    });
  }

  handleScriptCreate() {}
  handleScriptError() {
    this.setState({
      scriptError: true,
    });
  }

  handleScriptLoad() {
    VuppetMasterHelper.init();
    VuppetMasterHelper.on('complete', async () => {
      this.setState({
        progress: null,
      });
      this.setState({ vmReady: true });

      // check if logo
      const logo = this.props.center.shirt_media_uuid; //?this.props.center.shirt_media_uuid:this.props.center.image_media_uuid;
      if (logo) {
        const mediaImage = this.props.media.find(
          (entry) => entry.uuid === logo
        );
        if (mediaImage) {
          const url = getMediaURL(mediaImage);
          VuppetMasterHelper.initAvatar(url);
        }
      }
      StateMachine.send('GAME_LOADED');
    });

    VuppetMasterHelper.on('project.progress', (value) => {
      this.setState({ progress: value });
    });
  }

  render() {
    const { login, apibase, xstate, debugMode } = this.props;
    const {
      backgroundURL,
      progress,
      serverConnect,
      mouseData,
      session_uuid,
      showLogout,
      showVersion,
      loadVM,
      settings,
      motiv,
      priceCard,
      cursorMode,
    } = this.state;
    const vmurl = RestAPI.getVM() + '/api?key=' + sbKey;

    if (maryamFlag) {
      return (
        <div className={styles.main}>
          <div
            style={{
              position: 'absolute',
              top: '20%',
              left: '20%',
              width: '50%',
              height: '50%',
            }}
          >
            <PlayGround
              mouseData={mouseData}
              row={4}
              col={4}
              space={5}
              onCheck={(result) => {
                console.log('### onCheck');
              }}
              onFinished={() => {
                console.log('### onFinished');
              }}
              onStart={() => {
                console.log('onStatrt');
              }}
            />
          </div>
          <div style={{ position: 'absolute', bottom: '0' }}>
            <ControlCenter
              onClick={(x, y) => {
                this.setState({
                  mouseData: {
                    mode: 'click',
                  },
                });
              }}
              onMove={(x, y) => {
                this.setState({
                  mouseData: {
                    mode: 'move',
                    x,
                    y,
                  },
                });
              }}
              onEnd={(x, y) => {
                this.setState({
                  mouseData: {
                    mode: 'end',
                    x,
                    y,
                  },
                });
              }}
            />
          </div>
        </div>
      );
    }

    const init = ['init', 'checkAuth', 'waitlogin'].includes(xstate);
    const game = [
      'game_intro',
      'mobile_connected',
      'game_countdown',
      'game_play_intro',
      'game_create_session',
      'game_wait',
      'game_play',
      'game_loose',
      'game_loose_nocenterpair',
      'game_win',
      'game_end',
    ].includes(xstate);

    const showImprint = !game && !xstate === 'sleep';

    const backend = login && includesOneOf(login.scope, ['admin', 'root']);

    return (
      <div
        className={cx(
          styles.main,
          backend && styles.main_backend,
          game && process.env.NODE_ENV === 'production' && styles.main_game
        )}
      >
        <div className={styles.mainaspect}>
          <Helmet>
            <title>{this.state.title}</title>
          </Helmet>
          {!game && (
            <div
              className={cx(styles.background)}
              style={{
                backgroundImage: `url(${backgroundURL})`,
              }}
            />
          )}
          {init && <div></div>}
          {!init && (
            <Switch>
              <Route path="/" exact render={(props) => <div>Loading</div>} />
              <Route path="/login" render={(props) => <Login />} />
              {backend && (
                <Route path="/admin" render={(props) => <BackendMain />} />
              )}
              {login && (
                <Route
                  path="/game"
                  render={(props) => (
                    <div>
                      <Game
                        cursorMode={cursorMode}
                        priceCard={priceCard}
                        motiv={motiv}
                        settings={settings}
                        onCheck={(result) => {
                          if (xstate === 'game_play') {
                            // check time

                            if (result)
                              VuppetMasterHelper.playAnimationByName('correct');
                            else
                              VuppetMasterHelper.playAnimationByName('wrong');
                          }
                        }}
                        onFinished={(flag) => {
                          if (flag) StateMachine.send('FINISHED_CENTER');
                          else StateMachine.send('FINISHED');
                        }}
                        mouseData={mouseData}
                        gameDuration={gameTimeout}
                        session_uuid={session_uuid}
                      />
                      {progress && <ProgressBar value={progress} />}

                      {showLogout && (
                        <div className={styles.logout}>
                          <Button
                            icon
                            onClick={() => {
                              this.setState({ loadVM: false });
                              store.dispatch(actionSetLogin(null));
                              VuppetMasterHelper.destroy();
                              StateMachine.send('LOGOUT');
                            }}
                          >
                            <Icon name={'log out'} />
                            Logout
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                />
              )}
            </Switch>
          )}
          {apibase && login && loadVM && (
            <Script
              url={vmurl}
              onCreate={this.handleScriptCreate.bind(this)}
              onError={this.handleScriptError.bind(this)}
              onLoad={this.handleScriptLoad.bind(this)}
            />
          )}

          <RenderView hidden={!game} />

          {!backend && (process.env.NODE_ENV !== 'production' || debugMode) && (
            <div className={styles.debuginfo}>
              <Message color={'red'}>
                <div>State: {xstate}</div>
                <div>Server: {serverConnect ? 'connected' : ''}</div>
                <div>User uuid: {login && login.uuid}</div>
                <div>Game uuid: {login && login.game_uuid}</div>
                <div>Session uuid: {session_uuid}</div>
              </Message>
            </div>
          )}

          {showImprint && (
            <div className={styles.footer}>
              <Imprint />
            </div>
          )}
          {showVersion && (
            <div className={styles.version}>
              <Label>
                <p>
                  Build Date:{' '}
                  {preval`module.exports = new Date().toLocaleString();`}.
                </p>
              </Label>
            </div>
          )}
        </div>
        <div className={styles.hiddenxstate}>{xstate}</div>
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
MainPage = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    center: state.center.center,
    media: state.media,
    apibase: state.main.apibase,
    debugMode: state.main.debugMode,
    xstate: state.main.xstate,
  }),
  {} // bind account loading action creator
)(MainPage);

export default withRouter(MainPage);
