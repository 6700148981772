import React from 'react';
import QRCodeSizeable from '../../../1_atom/QRCodeSizeable/QRCodeSizeable.js';
import QRCode from 'qrcode.react';

import styles from './MobileWin.module.scss';

const MobileWin = (props) => {
  const { data } = props;

  const qrcode = window.location.pathname;

  return (
    <div className={styles.main}>
      <h1>Glückwunsch!</h1>
      <p>Du hast folgenden Gewinn erspielt</p>
      <p>Code {data && data.code}</p>
      Wie geht's weiter?
      <div style={{ textAlign: 'left' }}>
        <ol>
          <li>Screenshot von Gewinn-Code machen</li>
          <li>Ins Center Management gehen</li>
          <li>Code vorzeigen und Einlösen</li>
        </ol>
      </div>
      <div>
        <p>Code abfotografieren!</p>
        <div className={styles.qrcode}>
          <QRCode value={qrcode} size={200} />
        </div>
      </div>
    </div>
  );
};

export default MobileWin;
