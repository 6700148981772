const initialState = {
  games: [],
  game: null,
};

const reducerGame = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_GAMES':
      return {
        ...state,
        games: action.games,
      };
    case 'SET_GAME':
      return {
        ...state,
        game: action.game,
      };
    default:
      return state;
  }
};

export default reducerGame;
