import React, { Component } from 'react';
import { Input, Button } from 'semantic-ui-react';

class EditInline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.text && nextProps.text !== this.props.text)
      this.setState({ text: null });
  }

  onTextChanged(event) {
    this.setState({ text: event.target.value });
    this.props.onChanged(event.target.value);
  }

  render() {
    let { text, placeholder, label, type, apply } = this.props;

    return (
      <div style={{ marginBottom: '10px' }}>
        <div>{label}</div>
        <Input
          value={this.state.text != null ? this.state.text : text ? text : ''}
          placeholder={placeholder}
          onChange={(event) => {
            this.onTextChanged(event);
          }}
          type={type}
        ></Input>
        {apply && (
          <Button
            onClick={() => {
              this.props.onApply && this.props.onApply();
            }}
          >
            {apply}
          </Button>
        )}
      </div>
    );
  }
}

export default EditInline;
