import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import PlayGround from '../PlayGround/PlayGround.js';
import StartButton from '../StartButton/StartButton.js';
import StartButtonExcel from '../StartButtonExcel/StartButtonExcel.js';
import RestAPI from '../../../RestAPI.js';

import styles from './Game.module.scss';
import TimeCounter from '../../1_atom/TimeCounter/TimeCounter.js';
import QRCodeSizeable from '../../1_atom/QRCodeSizeable/QRCodeSizeable.js';
import StateMachine from '../../../services/StateMachine.js';
import { getMediaURL } from '../../../helpers/media';
import VuppetMasterHelper from '../../../helpers/VuppetMaster.helper.js';
import { Motion, spring } from 'react-motion';

const clone = require('clone');

class Game extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: this.props.gameDuration,
      percent: undefined,
      mediaValid: false,
      forcePrice: null,
      forceMode: null,
    };

    this._timerID = 0;
    this._events = [];
    this._x = 0;
    this._y = 0;
  }

  componentDidMount() {
    RestAPI.media(this.props.login.center_uuid).then(() => {
      this.setState({ mediaValid: true });
    });

    document.addEventListener('keydown', (event) => {
      if (event.key === '1') {
        let next = null;
        switch (this.state.forcePrice) {
          case null:
            next = 'yes';
            break;
          case 'yes':
            next = 'no';
            break;
          case 'no':
            next = null;
            break;
          default:
            break;
        }
        this.setState({
          forcePrice: next,
        });
      } else if (event.key === '2') {
        let mode = null;
        switch (this.state.forceMode) {
          case null:
            mode = 'pair';
            break;
          case 'pair':
            mode = 'combi';
            break;
          case 'combi':
            mode = null;
            break;
          default:
            break;
        }
        this.setState({
          forceMode: mode,
        });
      }
    });

    this._events.push(
      StateMachine.addEvent('game_play_intro', async () => {
        /*
          game_play_intro
        */
        this.setState({
          percent: 0,
        });
        const interval = 50;
        const duration = 1400;
        const step = duration / interval / 10;
        this._timerID = setInterval(() => {
          this.setState({
            percent: this.state.percent + step,
          });
        }, interval);
      })
    );

    this._events.push(
      StateMachine.addEvent('game_play', async () => {
        /*
          game_play
        */
        clearInterval(this._timerID);
        this.setState({
          percent: undefined,
        });

        this.setState({ time: this.props.settings.duration });

        let audio = new Audio(
          process.env.PUBLIC_URL + '/assets/sounds/alert_synthhall.mp3'
        );

        this.clearInterval();
        this._timerID = setInterval(() => {
          const time = this.state.time - 1;
          this.setState({ time });
          if (time === -1) {
            this.clearInterval();
          } else if (time < 10 && time !== 0) {
            audio.play();
          }
        }, 1000);
      })
    );

    StateMachine.addEvent('mobile_connected', async () => {
      /*
        mobile_connected
      */
      const settings = this.props.user.game_settings;
      const paarsucheSettings = settings['paarsuche'];
      let mode = this.state.forceMode
        ? this.state.forceMode
        : paarsucheSettings.mode;

      if (mode === 'pairs') {
        VuppetMasterHelper.playSequenceByName('joystick');
      } else {
        VuppetMasterHelper.playSequenceByName('joystick_combi');
      }
    });
  }

  componentWillUnmount() {
    this._events.forEach((uuid) => {
      StateMachine.releaseEvent(uuid);
    });
  }

  clearInterval() {
    if (this._timerID) clearInterval(this._timerID);
  }

  render() {
    const { time, percent, mediaValid, forcePrice, forceMode } = this.state;
    const {
      xstate,
      mouseData,
      session_uuid,
      onFinished,
      onCheck,
      gameDuration,
      media,
      login,
      center,
      settings,
      motiv,
      priceCard,
      cursorMode,
    } = this.props;

    let mobileURL = 'cyz';
    if (session_uuid)
      mobileURL = `${
        process.env.REACT_APP_QRCODEURL
          ? process.env.REACT_APP_QRCODEURL
          : RestAPI.getBase() + process.env.REACT_APP_DEPLOY
      }/mobilereg/${session_uuid}`;

    // motiv
    const image_motiv =
      center && motiv && media && media.find((entry) => entry.uuid === motiv);
    const img_stoerer = image_motiv && getMediaURL(image_motiv);

    const image_center =
      center &&
      center.image_media_uuid &&
      media &&
      media.find((entry) => entry.uuid === center.image_media_uuid);
    const img_center = image_center && getMediaURL(image_center);
    let square = true;
    if (image_center && image_center.width && image_center.height) {
      if (image_center.width > image_center.height) square = false;
    }

    // mode
    let newSettings = clone(settings);
    if (forceMode) {
      newSettings.mode = forceMode;
    }

    // show price cards
    let showPriceCard = priceCard;
    if (forcePrice === 'yes') showPriceCard = true;
    else if (forcePrice === 'no') showPriceCard = false;

    // Mousedata
    const factor = 100;
    if (mouseData) {
      if (mouseData.mode === 'move') {
        this._x = mouseData.x !== undefined ? mouseData.x : this._x;
        this._y = mouseData.y !== undefined ? mouseData.y : this._y;
      }
    }

    return (
      <div className={styles.main}>
        {forcePrice && (
          <div className={styles.prices}>Show Prices Cards:{forcePrice}</div>
        )}
        {forceMode && <div className={styles.mode}>Mode:{forceMode}</div>}
        {xstate === 'sleep' && <div className={styles.sleep}></div>}
        {xstate === 'game_press_start' && (
          <div className={styles.press_start}>
            <Button
              style={{ width: '200px' }}
              onClick={() => {
                StateMachine.send('PRESS');
              }}
            >
              <h2>Start Game</h2>
            </Button>
          </div>
        )}
        {xstate === 'game_wait' && (
          <div
            className={styles.qrcode}
            onClick={() => {
              // only for debugging
//              window.open(mobileURL, '_blank');
            }}
          >
            <QRCodeSizeable value={mobileURL} />
          </div>
        )}
        {xstate === 'mobile_connected' && (
          <div>
            <div className={styles.startButton}>
              <Motion
                style={{
                  x: spring(this._x * factor),
                  y: spring(this._y * factor),
                }}
              >
                {(interpolatingStyle) => {
                  if (cursorMode === 'smooth') {
                    return (
                      <StartButton
                        mouseData={{
                          ...mouseData,
                          x: interpolatingStyle.x / factor,
                          y: interpolatingStyle.y / factor,
                        }}
                        onClick={() => {
                          this.clearInterval();
                          StateMachine.send('MOBILE_START');
                        }}
                      />
                    );
                  } else {
                    return (
                      <StartButtonExcel
                        mouseData={{
                          ...mouseData,
                          x: interpolatingStyle.x / factor,
                          y: interpolatingStyle.y / factor,
                        }}
                        onClick={() => {
                          this.clearInterval();
                          StateMachine.send('MOBILE_START');
                        }}
                      />
                    );
                  }
                }}
              </Motion>
            </div>
          </div>
        )}

        {(xstate === 'game_play' || xstate === 'game_play_intro') &&
          media &&
          mediaValid && (
            <div>
              <TimeCounter percent={percent} time={time} max={gameDuration} />
              <div
                className={styles.playground}
                style={{ left: `${newSettings.col === 5 ? '10%' : '20%'}` }}
              >
                <Motion
                  style={{
                    x: spring(this._x * factor),
                    y: spring(this._y * factor),
                  }}
                >
                  {(interpolatingStyle) => {
                    return (
                      <PlayGround
                        cursorMode={cursorMode}
                        price={showPriceCard}
                        settings={newSettings}
                        center={login.center_uuid}
                        media={media}
                        mouseData={{
                          ...mouseData,
                          x: interpolatingStyle.x / factor,
                          y: interpolatingStyle.y / factor,
                        }}
                        space={5}
                        onCheck={(result) => {
                          onCheck(result);
                        }}
                        onFinished={(flag) => {
                          this.clearInterval();
                          onFinished(flag);
                        }}
                      />
                    );
                  }}
                </Motion>
              </div>
            </div>
          )}

        <div className={styles.game_main}>
          {img_stoerer &&
            (xstate === 'game_intro' ||
              xstate === 'game_create_session' ||
              xstate === 'game_wait' ||
              xstate === 'mobile_connected' ||
              xstate === 'game_play' ||
              xstate === 'game_countdown' ||
              xstate === 'game_loose' ||
              xstate === 'game_loose_nocenterpair' ||
              xstate === 'game_win' ||
              xstate === 'game_end' ||
              xstate === 'game_play_intro') && (
              <div className={styles.stoerer}>
                <div className={styles.stoerer_tri_container}>
                  <img
                    className={styles.stoerer_tri_image}
                    src={
                      process.env.PUBLIC_URL + '/assets/interferer_triangle.svg'
                    }
                    alt="_image"
                  />
                  <img
                    className={styles.stoerer_image}
                    src={img_stoerer}
                    alt="_image"
                  />
                </div>
              </div>
            )}
          {img_center &&
            (xstate === 'game_intro' ||
              xstate === 'game_create_session' ||
              xstate === 'game_wait' ||
              xstate === 'mobile_connected' ||
              xstate === 'game_play' ||
              xstate === 'game_countdown' ||
              xstate === 'game_loose' ||
              xstate === 'game_loose_nocenterpair' ||
              xstate === 'game_win' ||
              xstate === 'game_end' ||
              xstate === 'game_play_intro') && (
              <div className={styles.center}>
                <img
                  style={{ width: `${square ? '6em' : '12em'}` }}
                  className={styles.center_image}
                  src={img_center}
                  alt="_image"
                />
              </div>
            )}
        </div>
      </div>
    );
  }
}

Game = connect(
  (state) => ({
    user: state.user.user,
    login: state.login.login,
    media: state.media,
    center: state.center.center,
    xstate: state.main.xstate,
  }),
  {}
)(Game);

export default Game;
