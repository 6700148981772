//
// Redux init
//
import { createStore, combineReducers } from 'redux';

import reducerMain from './reducer/reducerMain';
import reducerUser from './reducer/reducerUser';
import reducerSession from './reducer/reducerSession';
import reducerCenter from './reducer/reducerCenter';
import reducerGame from './reducer/reducerGame';
import reducerLogin from './reducer/reducerLogin';
import reducerMedia from './reducer/reducerMedia';

const rootReducer = combineReducers({
  main: reducerMain,
  login: reducerLogin,
  user: reducerUser,
  media: reducerMedia,
  center: reducerCenter,
  game: reducerGame,
  session: reducerSession,
});
const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
