import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Message, Button, Icon, Card, Popup } from 'semantic-ui-react';
import EditLine from '../../../1_atom/EditLine/EditLine';
import Password from '../../../1_atom/Password/Password';

import { connect } from 'react-redux';
import store from '../../../../redux/store';

import styles from './Settings.module.scss';

import RestAPI from '../../../../RestAPI.js';
import { actionSetUser } from '../../../../redux/actions/actionUser.js';
import { actionSetCenter } from '../../../../redux/actions/actionCenter.js';
import UploadButton from '../../../3_organisms/UploadButton/UploadButton.js';
import { includesOneOf } from '../../../../helpers/scopecheck';

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      passwd: null,
      apply: false,
      applyPasswd: false,
      image_uuid: undefined,
      shirt_uuid: undefined,
    };

    RestAPI.users();
  }

  onApply() {
    if (!this.props.user) {
      return false;
    }

    let user = {
      ...this.props.user,
    };
    if (this.state.passwd) user.passwd = this.state.passwd;
    store.dispatch(actionSetUser(user));

    this.setState({ apply: false });
    RestAPI.updateUser(user).then((result) => {
      RestAPI.users();
    });

    let center = {
      ...this.props.center,
    };

    center.image_media_uuid =
      this.state.image_uuid !== undefined
        ? this.state.image_uuid
        : this.props.center.image_media_uuid;

    center.shirt_media_uuid =
      this.state.shirt_uuid !== undefined
        ? this.state.shirt_uuid
        : this.props.center.shirt_media_uuid;

    RestAPI.updateCenter(center).then((result) => {
      store.dispatch(actionSetCenter(center));
    });
  }

  render() {
    const { user, center, centers, media, login } = this.props;
    const { apply, applyPasswd } = this.state;

    const isRoot = includesOneOf(login && login.scope, ['root']);

    const cenerImageUUID = center && center.image_media_uuid;
    const uuid =
      this.state.image_uuid !== undefined
        ? this.state.image_uuid
        : cenerImageUUID;
    const center_image_media =
      media && media.find((entry) => entry.uuid === uuid);

    const shirtImageUUID = center && center.shirt_media_uuid;
    const shirtUuid =
      this.state.shirt_uuid !== undefined
        ? this.state.shirt_uuid
        : shirtImageUUID;
    const shirt_image_media =
      media && media.find((entry) => entry.uuid === shirtUuid);

    return (
      <div className={styles.main}>
        {user && (
          <div>
            <Message>
              <Message.Header>Account: {user.name}</Message.Header>
              <p />
              <Password
                onChanged={(text, valid) => {
                  this.setState({ passwd: text, applyPasswd: valid });
                }}
              />
              <div>
                {applyPasswd && (
                  <Button color={'green'} onClick={() => this.onApply()}>
                    Apply
                  </Button>
                )}
              </div>
            </Message>
            <Message>
              <Message.Header>Center Image</Message.Header>
              <p />
              <div className={styles.ui}>
                <div className={styles.ui_control}>
                  <Card.Group style={{ width: '210px' }}>
                    <Card>
                      <Card.Content>
                        {center_image_media ? (
                          <div>
                            <div>
                              <Popup
                                disabled={center_image_media ? false : true}
                                content={
                                  center_image_media && center_image_media.name
                                }
                                trigger={
                                  <img
                                    className={styles.centerImage}
                                    src={`${RestAPI.getRoot()}/media/${
                                      center_image_media.center_uuid
                                    }&${center_image_media.filename}`}
                                    alt="_image"
                                  />
                                }
                              />
                            </div>
                            <Button
                              size={'mini'}
                              icon
                              onClick={() => {
                                this.setState({
                                  apply: true,
                                  image_uuid: null,
                                });
                              }}
                            >
                              <Icon name="delete" />
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <img
                              className={styles.centerImage}
                              src={process.env.PUBLIC_URL + '/assets/empty.png'}
                              alt="_image"
                            />
                            <UploadButton
                              label={'Upload new center image'}
                              media={center_image_media}
                              onChanged={(media) => {
                                this.setState({
                                  apply: true,
                                  image_uuid: media.uuid,
                                });
                              }}
                            />
                          </div>
                        )}
                      </Card.Content>
                    </Card>
                  </Card.Group>
                </div>
                <div className={styles.ui_text}>
                  <p>
                    This image will be shown in the upper left corner of the
                    game.
                  </p>
                  <p>
                    The max resolution should not be greater than 1024 x 1024
                    pixel. Max image size 5MB.
                  </p>
                </div>
              </div>
            </Message>{' '}
            <Message>
              <Message.Header>Shirt Image</Message.Header>
              <p />
              <div className={styles.ui}>
                <div className={styles.ui_control}>
                  <Card.Group style={{ width: '210px' }}>
                    <Card>
                      <Card.Content>
                        {shirt_image_media ? (
                          <div>
                            <div>
                              <Popup
                                disabled={shirt_image_media ? false : true}
                                content={
                                  shirt_image_media && shirt_image_media.name
                                }
                                trigger={
                                  <img
                                    className={styles.shirtImage}
                                    src={`${RestAPI.getRoot()}/media/${
                                      shirt_image_media.center_uuid
                                    }&${shirt_image_media.filename}`}
                                    alt="_image"
                                  />
                                }
                              />
                            </div>
                            <Button
                              size={'mini'}
                              icon
                              onClick={() => {
                                this.setState({
                                  apply: true,
                                  shirt_uuid: null,
                                });
                              }}
                            >
                              <Icon name="delete" />
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <img
                              className={styles.centerImage}
                              src={process.env.PUBLIC_URL + '/assets/empty.png'}
                              alt="_image"
                            />
                            <UploadButton
                              label={'Upload new shirt image'}
                              media={shirt_image_media}
                              onChanged={(media) => {
                                this.setState({
                                  apply: true,
                                  shirt_uuid: media.uuid,
                                });
                              }}
                            />
                          </div>
                        )}
                      </Card.Content>
                    </Card>
                  </Card.Group>
                </div>
                <div className={styles.ui_text}>
                  <p>This image will be shown on the shirt of the avatar.</p>
                  <p>
                    The image have to be square and the max resolution should
                    not be greater than 1024 x 1024 pixel. Max image size 5MB.
                  </p>
                </div>
              </div>
              <div>
                {apply && (
                  <Button color={'green'} onClick={() => this.onApply()}>
                    Apply
                  </Button>
                )}
              </div>{' '}
            </Message>{' '}
          </div>
        )}
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Settings = connect(
  (state) => ({
    user: state.user.user,
    users: state.user.users,
    center: state.center.center,
    centers: state.center.centers,
    media: state.media,
  }),
  {}
)(Settings);

export default withRouter(Settings);
