import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.scss';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import MainPage from './components/4_views/MainPage/MainPage';
import MobileMain from './components/4_views/Mobile/MobileMain/MobileMain';
import store from './redux/store';
import 'semantic-ui-css/semantic.min.css';

const mobile =
  window.location.pathname.startsWith('/mobile') ||
  window.location.pathname.startsWith('/dev/mobile');
const mobileRegister =
  window.location.pathname.startsWith('/mobilereg') ||
  window.location.pathname.startsWith('/dev/mobilereg');

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter basename={process.env.REACT_APP_DEPLOY}>
      {mobile ? <MobileMain register={mobileRegister} /> : <MainPage />}
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
