import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  actionSetCenter,
  actionSetCenters,
} from '../../../../redux/actions/actionCenter';
import store from '../../../../redux/store';

import RestAPI, { users } from '../../../../RestAPI.js';
import DataTable from '../../../3_organisms/DataTable/DataTable';
import ModalOkCancel from '../../../3_organisms/ModalOkCancel';

import { includesOneOf } from '../../../../helpers/scopecheck';
import NewRowModal from '../../../3_organisms/NewRowModal/NewRowModal';

import styles from './Centers.module.scss';
import { Button } from 'semantic-ui-react';

class Centers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centerToDelete: null,
    };

    this.updateView();
  }

  updateView() {
    store.dispatch(actionSetCenters(null));
    RestAPI.centers();
  }

  onSelect(center) {}

  onAddNew(name) {
    let center = {
      name: name,
      created: new Date().toISOString(),
      modified: new Date().toISOString(),
    };

    RestAPI.createCenter(center).then((res) => {
      if (res && !res.error) {
        this.updateView();
      }
    });
  }

  onDelete(center) {
    RestAPI.deleteCenter(center).then((res) => {
      if (res && !res.error) {
        store.dispatch(actionSetCenter(null));
        this.updateView();
      }
    });
  }

  onSet(center) {
    if (center) {
      store.dispatch(actionSetCenter(center));
      RestAPI.media(center.uuid);
      this.props.history.push('/admin/center/' + center.uuid);
    }
  }

  render() {
    const { centers, login, users } = this.props;

    const { centerToDelete } = this.state;

    const canEdit = includesOneOf(login && login.scope, ['root']);

    const rows =
      centers &&
      centers.map((center, index) => {
        const arrayUsers =
          (users &&
            users
              .filter((user) => {
                return includesOneOf(user.scope, ['admin']);
              })

              .filter((entry) => entry.center_uuid === center.uuid)) ||
          [];
        const arrayStations =
          (users &&
            users
              .filter((user) => {
                return includesOneOf(user.scope, ['station']);
              })

              .filter((entry) => entry.center_uuid === center.uuid)) ||
          [];

        return {
          center,
          users: arrayUsers && arrayUsers.length,
          stations: arrayStations && arrayStations.length,
          name: center.name,
        };
      });

    const actions = [
      canEdit && {
        icon: 'external',
        color: 'blue',
        tooltip: 'edit center',
        func: (row) => this.onSet(row.center),
      },
      canEdit && {
        icon: 'delete',
        color: 'red',
        tooltip: 'delete center',
        func: (row) => this.setState({ centerToDelete: row.center }),
      },
    ];

    const actionsMenu = [
      canEdit && {
        text: 'Edit',
        icon: 'external',
        color: 'blue',
        tooltip: 'edit center',
        func: (row) => this.onSet(row.center),
      },
      canEdit && {
        text: 'Delete',
        icon: 'delete',
        color: 'red',
        tooltip: 'delete center',
        func: (row) => this.setState({ centerToDelete: row.center }),
      },
    ];

    const columns = [
      {
        name: 'Name',
        key: 'name',
        required: true,
        singleLine: true,
      },
      {
        name: 'Admins',
        key: 'users',
        required: true,
        singleLine: true,
      },
      {
        name: 'Stations',
        key: 'stations',
        required: true,
        singleLine: true,
      },
    ];

    return (
      <div className={styles.main}>
        <h3>Centers:</h3>
        <div>
          <DataTable
            tableProps={{ selectable: false, compact: true, celled: true }}
            name="centers"
            placeholder="Filter name"
            sortable={true}
            filter={'name'}
            columns={columns}
            rows={rows}
            actions={actions}
            actionsMenu={actionsMenu}
            onSelect={(row) => this.onSelect(row.center)}
            updateView={() => this.updateView()}
          />
        </div>
        <Button
          onClick={() => {
            this.onAddNew('new center');
          }}
        >
          Add new Center
        </Button>
        <ModalOkCancel
          text="Delete Center?"
          color="red"
          open={centerToDelete != null}
          onOK={() => {
            this.onDelete(centerToDelete);
            this.setState({ centerToDelete: null });
          }}
          onCancel={() => this.setState({ centerToDelete: null })}
        />
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Centers = connect(
  (state) => ({
    login: state.login.login,
    users: state.user.users,
    centers: state.center.centers,
  }),
  {} // bind account loading action creator
)(Centers);

export default withRouter(Centers);
