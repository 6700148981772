import React, { useState } from 'react';
import Cursor from '../../1_atom/Cursor/Cursor';

import useDimensions from 'react-cool-dimensions';
import { Motion, spring } from 'react-motion';

import styles from './StartButtonExcel.module.scss';
import reducerCenter from '../../../redux/reducer/reducerCenter';

const row = 3;
const col = 4;

const buttonCol = 2;
const buttonRow = 2;

let gX = 2;
let gY = 1;
let gHit = false;
let gTrigger = false;

const offsetX = -100;
const offsetY = -10;

const StartButtonExcel = (props) => {
  const { mouseData, onClick } = props;

  const { observe, width, height } = useDimensions({
    onResize: ({ height, width }) => {
      // Triggered whenever the size of the target is changed...

      // set start button
      setButton({
        width,
        height,
        y: 0,
        x: 0,
      });
    },
  });

  const [button, setButton] = useState(null);

  if (mouseData) {
    if (mouseData.mode === 'excel' || mouseData.mode === 'end') {
      if (mouseData.mode === 'end') {
        gTrigger = false;
      } else if (mouseData.mode === 'excel') {
        if (!gTrigger) {
          gTrigger = true;

          switch (mouseData.direction) {
            case 0:
              gX += 1;
              break;
            case 1:
              gX += 1;
              gY -= 1;
              break;
            case 2:
              gY -= 1;
              break;
            case 3:
              gX -= 1;
              gY -= 1;
              break;
            case 4:
              gX -= 1;
              break;
            case 5:
              gX -= 1;
              gY += 1;
              break;
            case 6:
              gY += 1;
              break;
            case 7:
              gX += 1;
              gY += 1;
              break;
            default:
              break;
          }
          if (gX >= col) gX = col - 1;
          if (gX < 0) gX = 0;
          if (gY >= row) gY = row - 1;
          if (gY < 0) gY = 0;
        }
      }
    }

    // check start
    if (button) {
      const hit = gX === buttonCol && gY === buttonRow;
      if (mouseData.mode === 'excel' || mouseData.mode === 'end') {
        gHit = hit;
      }
      if (true && mouseData.mode === 'click') {
        onClick();
      }
    }
  }

  const startButtonStyle = {
    position: 'absolute',
    left: button && button.x,
    top: button && button.y,
    width: button && button.width,
    height: button && button.height,
    backgroundColor: gHit ? 'rgba(103, 214, 103,0.75)' : 'rgba(0,0,0,0.75)',
  };

  const finalX = gX * width + -1.5 * width;
  const finalY = gY * height + -1.5 * height;

  return (
    <div className={styles.main} ref={observe}>
      <Motion
        style={{
          x: spring(finalX),
          y: spring(finalY),
        }}
      >
        {(interpolatingStyle) => {
          return (
            <Cursor position={[interpolatingStyle.x, interpolatingStyle.y]} />
          );
        }}
      </Motion>
      <div
        onClick={() => {
          onClick();
        }}
        className={styles.button}
        style={startButtonStyle}
      >
        Start
      </div>
    </div>
  );
};

export default StartButtonExcel;
