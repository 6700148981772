import React, { useState } from 'react';
import ReactNipple from 'react-nipple';

// all events supported by nipplejs are available as callbacks
// see https://github.com/yoannmoinet/nipplejs#start

import 'react-nipple/lib/styles.css';

const ControlCenter = (props) => {
  const { onMove, onEnd, onClick, onExcel } = props;

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [debounceClickID, setDebounceClickID] = useState(0);

  const [trigger1, setTrigger1] = useState(false);

  const [dir, setDir] = useState(-1);
  const [force, setForce] = useState(0);

  const debounceTime = 200;

  const factor = 1.2;
  const trigger1Value = 0.2;

  return (
    <div>
      <ReactNipple
        onEnd={(data) => {
          setOffset(position);
          console.log('onEnd ');
          onEnd(position.x, -position.y);
          setTrigger1(false);

          if (debounceClickID) {
            if (force === 0 && (dir === 4 || dir === -1)) {
              onClick();
            }
            setDebounceClickID(0);
          }
        }}
        onStart={(event) => {
          console.log('# onStart');
          setForce(0);
          if (debounceClickID) {
            clearTimeout(setDebounceClickID);
          }
          setDebounceClickID(
            setTimeout(() => {
              setDebounceClickID(0);
            }, debounceTime)
          );
        }}
        onMove={(evt, data) => {
          const x =
            Math.cos(data.angle.radian) * data.force * factor + offset.x;
          const y =
            Math.sin(data.angle.radian) * data.force * factor + offset.y;
          setPosition({ x, y });
          onMove(x, -y);

          // determine direction
          const angle = data.angle.degree;
          let direction = 0;
          if (angle < 22 && angle >= 337) direction = 0;
          else if (angle >= 22 && angle < 67) direction = 1;
          else if (angle >= 67 && angle < 112) direction = 2;
          else if (angle >= 112 && angle < 157) direction = 3;
          else if (angle >= 157 && angle < 202) direction = 4;
          else if (angle >= 202 && angle < 247) direction = 5;
          else if (angle >= 247 && angle < 292) direction = 6;
          else if (angle >= 292 && angle < 337) direction = 7;
          console.log('onMove ', direction, data.force);
          setDir(direction);
          setForce(data.force);
          if (data.force > trigger1Value) {
            if (!trigger1) {
              console.log('onExecl ', direction);
              onExcel(direction);
              setTrigger1(true);
            }
          }
        }}
        options={{ mode: 'dynamic', position: { top: '50%', left: '50%' } }}
        style={{
          // outline: '1px dashed red',
          // backgroundColor: 'red',
          height: 300,
          // if you pass position: 'relative', you don't need to import the stylesheet
        }}
      />
    </div>
  );
};

export default ControlCenter;
