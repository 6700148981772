import React from 'react';

import styles from './MobilePlay.module.scss';
import ControlCenter from '../../../1_atom/ControlCenter/ControlCenter.js';

const MobilePlay = (props) => {
  const { socketio, session_uuid, session } = props;

  const cursorMode = session.game_settings.cursorMode
    ? session.game_settings.cursorMode
    : 'smooth';

  return (
    <div className={styles.main}>
      <ControlCenter
        onClick={(x, y) => {
          socketio.emit('m2sMouse', {
            session_uuid,
            mode: 'click',
            x,
            y,
          });
        }}
        onEnd={(x, y) => {
          socketio.emit('m2sMouse', {
            session_uuid,
            mode: 'end',
            x,
            y,
          });
        }}
        onMove={(x, y) => {
          if (cursorMode === 'smooth')
            socketio.emit('m2sMouse', {
              session_uuid,
              mode: 'move',
              x,
              y,
            });
        }}
        onExcel={(direction) => {
          if (cursorMode === 'excel')
            socketio.emit('m2sMouse', {
              session_uuid,
              mode: 'excel',
              direction: direction,
            });
        }}
      />
    </div>
  );
};

export default MobilePlay;
