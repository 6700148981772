import React from 'react';
import styles from './Cursor.module.scss';

const Cursor = (props) => {
  const { position } = props;
  const cursorStyle = {
    left: position[0],
    top: position[1],
  };

  return (
    <div>
      <img
        alt="cursor"
        style={cursorStyle}
        className={styles.normal_hand}
        src={process.env.PUBLIC_URL + '/assets/hand_normal.png'}
      ></img>
    </div>
  );
};

export default Cursor;
