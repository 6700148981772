import React from 'react';

//import styles from './Imprint.module.scss';

const Imprint = () => (
  <div>
    {' '}
    <div>
      © Charamel GmbH –{' '}
      <a target="_blank" href="https://charamel.com" rel="noopener noreferrer">
        www.charamel.com
      </a>
    </div>
    <div>
      <a
        target="_blank"
        href="https://charamel.com/impressum"
        rel="noopener noreferrer"
      >
        Impressum
      </a>
    </div>
  </div>
);

export default Imprint;
