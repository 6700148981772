import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Button,
  Message,
  Icon,
  Dropdown,
  Card,
  Form,
  Radio,
  Popup,
  Checkbox,
} from 'semantic-ui-react';

import UploadButton from '../../../3_organisms/UploadButton/UploadButton.js';
import EditLine from '../../../1_atom/EditLine/EditLine.js';
import Password from '../../../1_atom/Password/Password.js';

import { connect } from 'react-redux';
import store from '../../../../redux/store';
import { actionSetUser } from '../../../../redux/actions/actionUser.js';

import styles from './Station.module.scss';

import { getMediaURL } from '../../../../helpers/media';

import RestAPI from '../../../../RestAPI.js';
import { includesOneOf } from '../../../../helpers/scopecheck';
import TimeField from 'react-simple-timefield';

const clone = require('clone');

class Station extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      passwd: null,
      apply: false,
      applyPasswd: false,
      invalid: false,
      settings: null,
      pricesPerDay: null,
      duration: null,
      start: null,
      end: null,
      timerActive: undefined,
      timeValid: true,
      motiv_uuid: undefined,
      motivBackgroundColor: undefined,
      row: null,
      col: null,
    };

    RestAPI.users();
    RestAPI.games();

    this.updateView();
  }

  updateView() {
    RestAPI.media(this.props.center.uuid);
  }

  onApply() {
    if (!this.props.user) {
      return false;
    }

    let user = {
      ...this.props.user,
    };

    if (this.state.duration)
      user.game_settings[this.props.game.type].duration = parseInt(
        this.state.duration
      );
    if (this.state.pricesPerDay)
      user.game_settings[this.props.game.type].pricesPerDay = parseInt(
        this.state.pricesPerDay
      );

    if (this.state.motiv_uuid)
      user.game_settings.motiv_uuid = this.state.motiv_uuid;

    if (this.state.start) user.game_settings.timer.start = this.state.start;
    if (this.state.end) user.game_settings.timer.end = this.state.end;
    if (this.state.timerActive !== undefined)
      user.game_settings.timer.active = this.state.timerActive;

    if (this.state.cursorMode)
      user.game_settings.cursorMode = this.state.cursorMode;

    if (this.state.row)
      user.game_settings[this.props.game.type].row = this.state.row;
    if (this.state.col)
      user.game_settings[this.props.game.type].col = this.state.col;

    this.setState({ apply: false });
    RestAPI.updateUser(user).then((result) => {
      RestAPI.user(user.uuid);
      RestAPI.users();
      this.props.history.push('/admin/stations');
    });
  }

  onApplyPassword() {
    if (!this.props.user) {
      return false;
    }

    if (this.props.users.find((u) => u.name === this.state.name)) {
      this.setState({ invalid: true });
      return;
    }

    let user = {
      ...this.props.user,
    };
    user.name = this.state.name !== '' ? this.state.name : this.props.user.name;
    if (this.state.passwd) user.passwd = this.state.passwd;

    this.setState({ applyPasswd: false });
    RestAPI.updateUser(user).then((result) => {
      RestAPI.user(user.uuid);
      RestAPI.users();
      this.props.history.push('/admin/stations');
    });
  }

  changeMode(mode) {
    let user = clone(this.props.user);
    let settings = user.game_settings[this.props.game.type];
    settings.mode = mode;
    store.dispatch(actionSetUser(user));
    this.updateView();
    this.setState({ apply: true });
  }

  changePair(uuid, media) {
    let user = clone(this.props.user);
    let settings = user.game_settings[this.props.game.type];
    settings.pairs = settings.pairs.map((entry) => {
      if (entry.uuid === uuid) {
        entry.media_uuids[0] = media ? media.uuid : null;
        entry.name[0] = media ? media.name : '';
      }
      return entry;
    });
    store.dispatch(actionSetUser(user));
    this.updateView();
    this.setState({ apply: true });
  }

  changeCombi(uuid, index, media) {
    let user = clone(this.props.user);
    let settings = user.game_settings[this.props.game.type];
    settings.combis = settings.combis.map((entry) => {
      if (entry.uuid === uuid) {
        entry.media_uuids[index] = media ? media.uuid : null;
        entry.name[index] = media ? media.name : '';
      }
      return entry;
    });
    store.dispatch(actionSetUser(user));
    this.updateView();
    this.setState({ apply: true });
  }

  changeWinners(uuid, index, media) {
    let user = clone(this.props.user);
    let settings = user.game_settings[this.props.game.type];
    settings.winners = settings.winners.map((entry) => {
      if (entry.uuid === uuid) {
        entry.media_uuids[index] = media ? media.uuid : null;
        entry.name[index] = media ? media.name : '';
      }
      return entry;
    });
    store.dispatch(actionSetUser(user));
    this.updateView();
    this.setState({ apply: true });
  }

  validateTime(start, end) {
    const toNumber = (date) => {
      const hh = parseInt(date[0] + date[1]);
      const mm = parseInt(date[3] + date[4]);
      return hh * 100 + mm;
    };

    if (!start || !end) {
      this.setState({
        timeValid: false,
      });
    } else {
      const nstart = toNumber(start);
      const nend = toNumber(end);
      this.setState({
        timeValid: nstart < nend ? true : false,
      });
    }
  }

  render() {
    const { user, login, game, mediaArray } = this.props;
    const {
      apply,
      applyPasswd,
      invalid,
      pricesPerDay,
      duration,
      start,
      end,
      timerActive,
      timeValid,
      motiv_uuid,
      motivBackgroundColor,
      row,
      col,
      cursorMode,
    } = this.state;

    const isRoot = includesOneOf(login && login.scope, ['root']);

    const settings = user && game && user.game_settings;
    const settingsPaarsuche = settings && settings[game.type];
    const mode = settingsPaarsuche && settingsPaarsuche.mode;

    const cardStyle = { display: 'inline-block', padding: '5px' };

    const PopupButton = (props) =>
      props.text && props.text !== '' ? (
        <Popup
          content={props.text}
          trigger={<Button {...props}>{props.children}</Button>}
        />
      ) : (
        <Button {...props}>{props.children}</Button>
      );

    /*

    settings: {
      paarsuche: {
        cards: [media_uuid,....]
      },
      timer: {
        start: 
        end:

      }
    }


     settingsPaarsuche:
     {
          combis: [
            {
              uuid:
              media_uuid[2],
              name[2],
            }
          ],
          pairs: [
            {
              uuid:
              media_uuid[2],
              name,
            }
          ],
          winner: [
            {
              uuid:
              media_uuid[2],
              name,
            }
          ]
          motiv_uuid: media_uuid,
          mode: 'pairs''combis'
          row:
          col:
     }

    */

    const Pairs = (props) => {
      const pairs = settingsPaarsuche.pairs;
      return (
        <Card.Group>
          {pairs.map((pair) => {
            const media_uuid = pair.media_uuids[0];
            const media =
              mediaArray &&
              mediaArray.find((entry) => entry.uuid === media_uuid);

            let url = process.env.PUBLIC_URL + '/' + pair.default_images[0];
            if (media) url = getMediaURL(media);

            return (
              <Card key={pair.uuid}>
                <Card.Content>
                  <div>
                    <div style={cardStyle}>
                      <Popup
                        disabled={pair.name[0] ? false : true}
                        content={pair.name[0]}
                        trigger={
                          <img
                            className={styles.image}
                            src={url}
                            alt="_image"
                          />
                        }
                      />
                    </div>
                    <div style={cardStyle}>
                      <Popup
                        disabled={pair.name[0] ? false : true}
                        content={pair.name[0]}
                        trigger={
                          <img
                            className={styles.image}
                            src={url}
                            alt="_image"
                          />
                        }
                      />
                    </div>
                    <div>
                      <Button.Group size={'mini'}>
                        <UploadButton
                          label={'Upload new pair image'}
                          media={mediaArray}
                          id={`file${pair.uuid}`}
                          onChanged={(media) => {
                            this.changePair(pair.uuid, media);
                          }}
                        />
                        {media && (
                          <div className={styles.delete}>
                            <PopupButton
                              text={'remove image'}
                              color={'red'}
                              size={'mini'}
                              icon
                              onClick={() => {
                                this.changePair(pair.uuid, null);
                              }}
                            >
                              <Icon name="delete" />
                            </PopupButton>
                          </div>
                        )}
                      </Button.Group>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            );
          })}
        </Card.Group>
      );
    };

    const Combis = (props) => {
      const combis = settingsPaarsuche.combis;
      return (
        <Card.Group>
          {combis.map((pair) => {
            const media_a_uuid = pair.media_uuids[0];
            const media_a =
              mediaArray &&
              mediaArray.find((entry) => entry.uuid === media_a_uuid);

            const media_b_uuid = pair.media_uuids[1];
            const media_b =
              mediaArray &&
              mediaArray.find((entry) => entry.uuid === media_b_uuid);

            let url_a = process.env.PUBLIC_URL + '/' + pair.default_images[0];
            if (media_a) url_a = getMediaURL(media_a);
            let url_b = process.env.PUBLIC_URL + '/' + pair.default_images[1];
            if (media_b) url_b = getMediaURL(media_b);

            return (
              <Card key={pair.uuid}>
                <Card.Content>
                  <div>
                    <div style={cardStyle}>
                      <Popup
                        disabled={pair.name[0] ? false : true}
                        content={pair.name[0]}
                        trigger={
                          <img
                            className={styles.image}
                            src={url_a}
                            alt="_image"
                          />
                        }
                      />
                      <div>
                        <Button.Group size={'mini'}>
                          {media_a && (
                            <div className={styles.delete}>
                              <PopupButton
                                text={'remove image'}
                                color={'red'}
                                size={'mini'}
                                icon
                                onClick={() => {
                                  this.changeCombi(pair.uuid, 0, null);
                                }}
                              >
                                <Icon name="delete" />
                              </PopupButton>
                            </div>
                          )}
                          <UploadButton
                            label={'Upload new combi image'}
                            media={mediaArray}
                            id={`file${pair.uuid}a`}
                            onChanged={(media) => {
                              this.changeCombi(pair.uuid, 0, media);
                            }}
                          />
                        </Button.Group>
                      </div>
                    </div>
                    <div style={cardStyle}>
                      <Popup
                        disabled={pair.name[1] ? false : true}
                        content={pair.name[1]}
                        trigger={
                          <img
                            className={styles.image}
                            src={url_b}
                            alt="_image"
                          />
                        }
                      />

                      <div>
                        <Button.Group size={'mini'}>
                          <UploadButton
                            label={'Upload new combi image'}
                            media={mediaArray}
                            id={`file${pair.uuid}b`}
                            onChanged={(media) => {
                              this.changeCombi(pair.uuid, 1, media);
                            }}
                          />
                          {media_b && (
                            <div className={styles.delete}>
                              <PopupButton
                                text={'remove image'}
                                color={'red'}
                                size={'mini'}
                                icon
                                onClick={() => {
                                  this.changeCombi(pair.uuid, 0, null);
                                }}
                              >
                                <Icon name="delete" />
                              </PopupButton>
                            </div>
                          )}
                        </Button.Group>
                      </div>
                    </div>
                  </div>
                </Card.Content>
              </Card>
            );
          })}
        </Card.Group>
      );
    };

    const Winners = (props) => {
      const winners = settingsPaarsuche.winners;
      return (
        <div className={styles.ui}>
          <div className={styles.ui_control}>
            <Card.Group>
              {winners.map((pair) => {
                const media_a_uuid = pair.media_uuids[0];
                const media_a =
                  mediaArray &&
                  mediaArray.find((entry) => entry.uuid === media_a_uuid);

                let url_a = null;
                if (media_a) url_a = getMediaURL(media_a);

                return (
                  <Card key={pair.uuid}>
                    <Card.Content>
                      <div>
                        <div style={cardStyle}>
                          {media_a ? (
                            <div>
                              <Popup
                                content={pair.name[0]}
                                trigger={
                                  <img
                                    className={styles.image}
                                    src={url_a}
                                    alt="_image"
                                  />
                                }
                              />
                              <div className={styles.delete}>
                                <PopupButton
                                  text={'remove image'}
                                  color={'red'}
                                  size={'mini'}
                                  icon
                                  onClick={() => {
                                    this.changeWinners(pair.uuid, 0, null);
                                  }}
                                >
                                  <Icon name="delete" />
                                </PopupButton>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <img
                                className={styles.image}
                                src={
                                  process.env.PUBLIC_URL + '/assets/empty.png'
                                }
                                alt="_image"
                              />
                              <UploadButton
                                label={'Upload new winner image'}
                                media={mediaArray}
                                id={`file${pair.uuid}c`}
                                onChanged={(media) => {
                                  this.changeWinners(pair.uuid, 0, media);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </Card.Content>
                  </Card>
                );
              })}
            </Card.Group>
          </div>
          <div className={styles.ui_text}>
            <p>Please insert the winner card with the proces to be played.</p>
            <p>
              The image have to be square and the max resolution should not be
              greater than 1024 x 1024 pixel. Max image size 5MB.
            </p>
          </div>
        </div>
      );
    };

    const Motiv = (props) => {
      const uuid = motiv_uuid !== undefined ? motiv_uuid : settings.motiv_uuid;

      const media_a =
        mediaArray && mediaArray.find((entry) => entry.uuid === uuid);

      let url_a = null;
      if (media_a) url_a = getMediaURL(media_a);

      const color =
        motivBackgroundColor !== undefined
          ? motivBackgroundColor
          : settings.motivBackgroundColor;

      return (
        <div className={styles.ui}>
          <div className={styles.ui_control}>
            <Card.Group style={{ width: '210px' }}>
              <Card>
                <Card.Content>
                  <div>Motiv Störer</div>

                  {media_a ? (
                    <div>
                      <Popup
                        content={media_a.name}
                        trigger={
                          <img
                            className={styles.image_stoerer}
                            src={url_a}
                            alt="_image"
                          />
                        }
                      />
                      <div className={styles.delete}>
                        <PopupButton
                          text={'remove image'}
                          color={'red'}
                          size={'mini'}
                          icon
                          onClick={() => {
                            this.setState({ motiv_uuid: null });
                          }}
                        >
                          <Icon name="delete" />
                        </PopupButton>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <img
                        className={styles.image_stoerer}
                        src={process.env.PUBLIC_URL + '/assets/empty.png'}
                        alt="_image"
                      />
                      <UploadButton
                        label={'Upload new image'}
                        media={mediaArray}
                        id={`file${'motiv_uuid'}c`}
                        onChanged={(media) => {
                          this.setState({ motiv_uuid: media.uuid });
                        }}
                      />
                    </div>
                  )}
                </Card.Content>
              </Card>
            </Card.Group>
          </div>
          <div className={styles.ui_text}>
            <p>Image will be shown in the upper right corner of the game</p>
            <p>
              The image have to be square and the max resolution should not be
              greater than 1024 x 1024 pixel. Max image size 5MB.
            </p>
          </div>
        </div>
      );
    };

    const string2rowcol = (text) => {
      return {
        col: parseInt(text[0]),
        row: parseInt(text[4]),
      };
    };

    const rowcol2string = ({ row, col }) => {
      return `${col} x ${row}`;
    };

    const optCards = ['4 x 4', '5 x 4'].map((entry) => {
      return {
        key: entry,
        text: entry,
        value: entry,
      };
    });

    const timer =
      timerActive !== undefined ? timerActive : settings.timer.active;

    return (
      <div className={styles.main}>
        {user && settingsPaarsuche && (
          <div>
            <h1>Station: Paarsuche</h1>
            <Message>
              {isRoot ? (
                <div>
                  <Message.Header>Account</Message.Header>
                  <p />
                  <EditLine
                    label={'Name:'}
                    text={user.name}
                    onChanged={(text) => {
                      this.setState({
                        name: text,
                        applyPasswd: true,
                        invalid: false,
                      });
                    }}
                  />
                  {invalid && <div style={{ color: 'red' }}>name exists</div>}
                </div>
              ) : (
                <Message.Header>Account {user.name}</Message.Header>
              )}
              <br />
              <Password
                onChanged={(passwd, valid) => {
                  this.setState({ passwd: passwd, applyPasswd: valid });
                }}
              />
              {applyPasswd && (
                <PopupButton
                  active={applyPasswd}
                  text={'Apply password and name'}
                  color={'green'}
                  onClick={() => this.onApplyPassword()}
                >
                  Apply
                </PopupButton>
              )}
            </Message>

            <Message>
              <Message.Header>Settings</Message.Header>
              <p />
              <Motiv />

              <p />
              <div>Number Cards:</div>
              <div className={styles.ui}>
                <div className={styles.ui_control}>
                  <Dropdown
                    placeholder="Cards"
                    value={rowcol2string({
                      row: row ? row : settingsPaarsuche.row,
                      col: col ? col : settingsPaarsuche.col,
                    })}
                    selection
                    options={optCards}
                    onChange={(e, d) => {
                      const data = string2rowcol(d.value);
                      this.setState({
                        col: data.col,
                        row: data.row,
                      });
                    }}
                  />
                </div>
                <div className={styles.ui_text}>
                  <p>Change the number of cards.</p>
                  <p>Possible variations:</p>
                  <p> 4 x 4</p>
                  <p> 5 x 4</p>
                </div>
              </div>
              <p />
              <div>Duration (in sec):</div>
              <div className={styles.ui}>
                <div className={styles.ui_control}>
                  <div>
                    <div className="ui input">
                      <input
                        type="text"
                        style={{ width: '50px' }}
                        pattern={'[0-9]*'}
                        onInput={(evt) => {
                          if (evt.target.validity.valid) {
                            if (evt.target.value > 10) {
                              this.setState({
                                duration: evt.target.value
                                  ? evt.target.value
                                  : settingsPaarsuche.duration,
                              });
                            }
                          }
                        }}
                        value={duration ? duration : settingsPaarsuche.duration}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.ui_text}>
                  <p>Play time of the game.</p>
                </div>
              </div>

              <p />
              <div>Prices per Day:</div>

              <div className={styles.ui}>
                <div className={styles.ui_control}>
                  <div className="ui input">
                    <input
                      style={{ width: '50px' }}
                      type="text"
                      pattern={'[0-9]*'}
                      onInput={(evt) => {
                        this.setState({
                          pricesPerDay: evt.target.validity.valid
                            ? evt.target.value
                            : pricesPerDay
                            ? pricesPerDay
                            : settingsPaarsuche.pricesPerDay,
                        });
                      }}
                      value={
                        pricesPerDay
                          ? pricesPerDay
                          : settingsPaarsuche.pricesPerDay
                      }
                    />
                  </div>
                </div>
                <div className={styles.ui_text}>
                  <p>How many proces are to be played per day?</p>
                </div>
              </div>
              <p />
              {isRoot && (
                <Checkbox
                  label="CursorMode: (smooth)"
                  checked={
                    (cursorMode
                      ? cursorMode
                      : settings.cursorMode
                      ? settings.cursorMode
                      : 'smooth') === 'smooth'
                      ? true
                      : false
                  }
                  onChange={(event, data) => {
                    this.setState({
                      cursorMode: data.checked ? 'smooth' : 'excel',
                    });
                  }}
                />
              )}

              <div className={styles.ui}>
                <div className={styles.ui_control}>
                  <Checkbox
                    label="Timer"
                    checked={
                      timerActive !== undefined
                        ? timerActive
                        : settings.timer.active
                    }
                    onChange={(event, data) => {
                      this.setState({
                        timerActive: data.checked,
                      });
                    }}
                  />
                  <div className={!timer && styles.timer_not_active}>
                    <div>Start Time:</div>
                    <div className="ui input">
                      {timer ? (
                        <TimeField
                          value={start ? start : settings.timer.start} // {String}   required, format '00:00' or '00:00:00'
                          onChange={(evt, value) => {
                            const newStart = evt.target.validity.valid
                              ? evt.target.value
                              : start
                              ? start
                              : settings.start;
                            this.setState({
                              start: newStart,
                            });
                            this.validateTime(
                              newStart,
                              end ? end : settings.timer.end
                            );
                          }} // {Function} required
                          input={<input type="text" />} // {Element}  default: <input type="text" />
                          inputRef={(ref) => {}} // {Function} input's ref
                          colon=":" // {String}   default: ":"
                          showSeconds={false} // {Boolean}  default: false
                        />
                      ) : (
                        <TimeField
                          value={'00:00'}
                          input={<input type="password" />} // {Element}  default: <input type="text" />
                          colon=":" // {String}   default: ":"
                          showSeconds={false} // {Boolean}  default: false
                        />
                      )}
                    </div>
                    <div>End Time:</div>
                    <div className="ui input">
                      {timer ? (
                        <TimeField
                          value={end ? end : settings.timer.end} // {String}   required, format '00:00' or '00:00:00'
                          onChange={(evt, value) => {
                            const newEnd = evt.target.validity.valid
                              ? evt.target.value
                              : end
                              ? end
                              : settings.end;
                            this.setState({
                              end: newEnd,
                            });
                            this.validateTime(
                              start ? start : settings.timer.start,
                              newEnd
                            );
                          }} // {Function} required
                          input={<input type="text" />} // {Element}  default: <input type="text" />
                          inputRef={(ref) => {}} // {Function} input's ref
                          colon=":" // {String}   default: ":"
                          showSeconds={false} // {Boolean}  default: false
                        />
                      ) : (
                        <TimeField
                          value={'00:00'}
                          input={<input type="password" />} // {Element}  default: <input type="text" />
                          colon=":" // {String}   default: ":"
                          showSeconds={false} // {Boolean}  default: false
                        />
                      )}
                    </div>
                    {!timeValid && (
                      <div className={styles.diff}>Invalid Period</div>
                    )}
                  </div>
                </div>
                <div className={styles.ui_text}>
                  <p>Start end end time of playing time.</p>
                </div>
              </div>
            </Message>

            <Message>
              <Message.Header>Cards</Message.Header>
              <p />

              <div className={styles.ui}>
                <div className={styles.ui_control}>
                  <Form>
                    <Form.Field>
                      <Radio
                        label="Pairs"
                        name="radioGroup"
                        value={'pair'}
                        checked={mode === 'pairs'}
                        onChange={() => {
                          this.changeMode('pairs');
                        }}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Radio
                        label="Combis"
                        name="radioGroup"
                        value={'combis'}
                        checked={mode === 'combis'}
                        onChange={() => {
                          this.changeMode('combis');
                        }}
                      />
                    </Form.Field>
                  </Form>
                </div>
                <div className={styles.ui_text}>
                  <p>
                    You can replace the default images with your own images.
                  </p>
                  <p>
                    The images have to be square and the max resolution should
                    not be greater than 1024 x 1024 pixel. Max image size 5MB.
                  </p>
                </div>
              </div>

              <p />
              {mode && mode === 'pairs' && <Pairs />}
              {mode && mode === 'combis' && <Combis />}
            </Message>

            <Message>
              <Message.Header>Winner Card</Message.Header>
              {settingsPaarsuche.winners && <Winners />}
            </Message>
            <PopupButton
              active={apply}
              text={'Apply changes'}
              color={'green'}
              onClick={() => this.onApply()}
            >
              Apply
            </PopupButton>
          </div>
        )}
        <br />
        <br />
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Station = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    users: state.user.users,
    game: state.game.game,
    center: state.center.center,
    mediaArray: state.media,
  }),
  {}
)(Station);

export default withRouter(Station);
