import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Form, Message, Button, TextArea } from 'semantic-ui-react';

import { connect } from 'react-redux';
import store from '../../../../redux/store';

import styles from './Session.module.scss';
import RestAPI from '../../../../RestAPI.js';
import { includesOneOf } from '../../../../helpers/scopecheck';
import { actionSetSession } from '../../../../redux/actions/actionSession.js';
import moment from 'moment';

class Session extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: null,
      apply: false,
    };

    this.updateView();
  }

  updateView() {}

  onApply() {
    if (!this.props.session) {
      return false;
    }

    let session = {
      ...this.props.session,
    };
    session.text =
      this.state.text !== null ? this.state.text : this.props.center.text;

    store.dispatch(actionSetSession(session));

    RestAPI.updateSession(session).then((result) => {
      RestAPI.sessions(this.props.center.uuid);
      this.props.history.push('/admin/sessions');
    });
  }

  render() {
    const { session } = this.props;
    const { apply, text } = this.state;

    return (
      <div className={styles.main}>
        <h1>Session</h1>
        <Message>
          <Message.Header>Date:</Message.Header>{' '}
          {session && moment(session.started).format('DD:MM:YYYY HH:mm:ss')}
        </Message>
        {session && (
          <Message>
            <Message.Header>Notes:</Message.Header>
            <Form>
              <TextArea
                placeholder="Enter text"
                value={text ? text : session.text}
                onChange={(e, { value }) => {
                  this.setState({ text: value, apply: true });
                }}
              />
            </Form>
          </Message>
        )}
        <Button
          active={apply}
          floated="left"
          color={'green'}
          onClick={() => this.onApply()}
        >
          Apply
        </Button>
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Session = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    users: state.user.users,
    game: state.game.game,
    center: state.center.center,
    session: state.session.session,
  }),
  {}
)(Session);

export default withRouter(Session);
