import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './Users.module.scss';
import { connect } from 'react-redux';
import {
  actionSetUser,
  actionSetUsers,
} from '../../../../redux/actions/actionUser';
import store from '../../../../redux/store';
import { Button, Icon } from 'semantic-ui-react';

import RestAPI from '../../../../RestAPI.js';
import DataTable from '../../../3_organisms/DataTable/DataTable';
import ModalOkCancel from '../../../3_organisms/ModalOkCancel';

import { includesOneOf } from '../../../../helpers/scopecheck';
import NewRowModal from '../../../3_organisms/NewRowModal/NewRowModal';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userToDelete: null,
    };
  }

  updateView() {
    store.dispatch(actionSetUsers(null));
    RestAPI.users();
  }

  onSelect(user) {
    store.dispatch(actionSetUser(user));
  }

  onAddNew(name, scope) {
    let user = {
      name: name,
      center_uuid: this.props.center.uuid,
      scope: [scope],
      active: true,
      created: new Date().toISOString(),
      modified: new Date().toISOString(),
    };

    RestAPI.createUser(user).then((res) => {
      if (res && !res.error) {
        this.updateView();
      }
    });
  }

  onDelete(user) {
    RestAPI.deleteUser(user).then((res) => {
      if (res && !res.error) {
        store.dispatch(actionSetUser(null));
        this.updateView();
      }
    });
  }

  onSet(user) {
    if (user) {
      store.dispatch(actionSetUser(user));
      this.props.history.push('/admin/user/' + user.uuid);
    }
  }

  render() {
    const { center, users, login } = this.props;

    const { userToDelete } = this.state;

    const isRoot = includesOneOf(login && login.scope, ['root']);
    const isAdmin = includesOneOf(login && login.scope, ['admin']);

    const rows =
      users &&
      center &&
      users
        .filter((user) => user.center_uuid === center.uuid)
        .filter((user) => includesOneOf(user.scope, ['admin']))
        .map((user, index) => {
          return {
            name: user.name,
            user: user,
          };
        });

    const actions = [
      {
        icon: 'external',
        color: 'blue',
        tooltip: 'edit user',
        func: (row) => this.onSet(row.user),
      },
      {
        icon: 'delete',
        color: 'red',
        tooltip: 'delete user',
        func: (row) => this.setState({ userToDelete: row.user }),
      },
    ];

    const actionsMenu = [
      {
        text: 'Edit',
        icon: 'external',
        color: 'blue',
        tooltip: 'edit user',
        func: (row) => this.onSet(row.user),
      },
      {
        text: 'Delete',
        icon: 'delete',
        color: 'red',
        tooltip: 'delete user',
        func: (row) => this.setState({ userToDelete: row.user }),
      },
    ];

    const columns = [
      {
        name: 'Name',
        key: 'name',
        required: true,
        singleLine: true,
      },
    ];

    return (
      <div className={styles.main}>
        {isRoot ? (
          <h3>Admins of Center: {center && center.name}</h3>
        ) : (
          <h3>Admins:</h3>
        )}

        <div>
          <DataTable
            tableProps={{ selectable: false, compact: true, celled: true }}
            name="users"
            placeholder="Filter name"
            sortable={true}
            filter={'name'}
            columns={columns}
            rows={rows}
            actions={actions}
            actionsMenu={actionsMenu}
            onSelect={(row) => this.onSelect(row.user)}
            updateView={() => this.updateView()}
          />
          {isRoot && (
            <Button
              icon
              onClick={() => {
                this.onAddNew('admin', 'admin');
              }}
            >
              <Icon name="add" />
              Add new user
            </Button>
          )}
        </div>
        <ModalOkCancel
          text="Delete User?"
          color="red"
          open={userToDelete != null}
          onOK={() => {
            this.onDelete(userToDelete);
            this.setState({ userToDelete: null });
          }}
          onCancel={() => this.setState({ userToDelete: null })}
        />
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Users = connect(
  (state) => ({
    login: state.login.login,
    user: state.user.user,
    users: state.user.users,
    centers: state.center.centers,
    center: state.center.center,
  }),
  {} // bind account loading action creator
)(Users);

export default withRouter(Users);
