import React from 'react';
import cx from 'classnames';
import styles from './MobileInit.module.scss';

const MobileInit = (props) => {
  return (
    <div className={styles.container}>
      Connecting.....
      <div className={cx(styles.item, styles.one)}></div>
      <div className={cx(styles.item, styles.two)}></div>
      <div className={cx(styles.item, styles.three)}></div>
      <div className={cx(styles.item, styles.four)}></div>
    </div>
  );
};

export default MobileInit;
