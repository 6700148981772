import RestAPI from '../RestAPI';
import StateMachine from './StateMachine';

import store from '../redux/store';

const dayjs = require('dayjs');

let _start = -1;
let _end = -1;
let _sleep = false;
let _initialized = false;

const check = async (user) => {
  const currentTime = dayjs().hour() * 100 + dayjs().minute();
  const result = await RestAPI.user(user.uuid);
  if (result && result.game_settings && result.game_settings.timer) {
    console.log('# Timer check ok');
    const timer = result.game_settings.timer;
    if (timer.active) {
      const convertToNumber = (date) => {
        const hh = parseInt(date[0] + date[1]);
        const mm = parseInt(date[3] + date[4]);
        return hh * 100 + mm;
      };
      _start = convertToNumber(result.game_settings.timer.start);
      _end = convertToNumber(result.game_settings.timer.end);

      let newSleep = currentTime < _start || currentTime >= _end;

      if (dayjs().day() === 0) {
        // sunday
        newSleep = true;
      }

      if (newSleep !== _sleep) {
        // change state
        _sleep = newSleep;
        StateMachine.send('SLEEP', { flag: newSleep });

        const state = store.getState();
        console.log('# TimerService xstate:', state.main.xstate);
        console.log('# TimerService sleep:', newSleep ? 'true' : 'false');
      }
    } else {
      if (_sleep) {
        _sleep = false;
        StateMachine.send('SLEEP', { flag: false });
      }
    }
  } else {
    console.log('# Timer check get failed');
  }
};

const TimerService = {
  init(user) {
    if (_initialized) return;
    _initialized = true;
    check(user);
    setInterval(async () => {
      check(user);
    }, 60 * 1000 * 1);
  },
};

export default TimerService;
