import React, { Component } from 'react';
import { Segment, Header, Form, Grid, Message, Icon } from 'semantic-ui-react';
import { Route, Switch, withRouter } from 'react-router-dom';

import 'semantic-ui-css/semantic.min.css';

import { connect } from 'react-redux';

import styles from './BackendMain.module.scss';
import DashboardContainer from '../DashboardContainer/DashboardContainer';
import AdminMenu from '../../../3_organisms/AdminMenu/AdminMenu';
import { includesOneOf } from './../../../../helpers/scopecheck';

import Users from '../Users/Users';
import User from '../Users/User';
import Stations from '../Stations/Stations';
import Station from '../Stations/Station';
import Centers from '../Centers/Centers';
import Center from '../Centers/Center';
import Sessions from '../Sessions/Sessions';
import Session from '../Sessions/Session';
import Settings from '../Settings/Settings';
import Support from '../Support/Support';
import RestAPI from '../../../../RestAPI';

class BackeendMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (includesOneOf(this.props.login.scope, ['root'])) {
      RestAPI.centers();
    }
  }

  render() {
    return (
      <div className={styles.main}>
        <DashboardContainer {...this.props}>
          <div className={styles.flexcontainer}>
            <div style={{ flex: '0 0 150px' }}>
              <AdminMenu
                scope={this.props.login && this.props.login.scope}
                history={this.props.history}
              />
            </div>
            <div>
              <Segment
                style={{
                  height: '100%',
                  overflow: 'auto',
                  padding: '0,0,0,0',
                }}
              >
                <div style={{ height: '100%' }}>
                  <Route
                    exact
                    path="/admin/center/:uuid"
                    render={(props) => {
                      return <div />;
                    }}
                  />
                  <Route
                    exact
                    path="/admin/support"
                    render={(props) => {
                      return <Support />;
                    }}
                  />
                  <Route
                    exact
                    path="/admin/center/:uuid"
                    render={(props) => {
                      return <Center />;
                    }}
                  />
                  <Route
                    exact
                    path="/admin/centers"
                    render={(props) => {
                      return <Centers />;
                    }}
                  />
                  <Route
                    exact
                    path="/admin/station/:uuid"
                    render={(props) => {
                      return <Station />;
                    }}
                  />
                  <Route
                    exact
                    path="/admin/stations"
                    render={(props) => {
                      return <Stations />;
                    }}
                  />
                  <Route
                    exact
                    path="/admin/settings/:uuid"
                    render={(props) => {
                      return <Settings />;
                    }}
                  />
                  <Route
                    exact
                    path="/admin/user/:uuid"
                    render={(props) => {
                      return <User />;
                    }}
                  />
                  <Route
                    exact
                    path="/admin/users"
                    render={(props) => {
                      return <Users />;
                    }}
                  />
                  <Route
                    exact
                    path="/admin/session/:uuid"
                    render={(props) => {
                      return <Session />;
                    }}
                  />
                  <Route
                    exact
                    path="/admin/sessions"
                    render={(props) => {
                      return <Sessions />;
                    }}
                  />
                </div>
              </Segment>
            </div>
          </div>
        </DashboardContainer>
      </div>
    );
  }
}

BackeendMain = connect(
  (state) => ({
    login: state.login.login,
    apibase: state.main.apibase,
    debugMode: state.main.debugMode,
    xstate: state.main.xstate,
  }),
  {} // bind account loading action creator
)(BackeendMain);

export default withRouter(BackeendMain);
