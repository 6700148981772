import React from 'react';

import styles from './Card.module.scss';
import { cx } from '../../../helpers/classNames.helper';

const Card = (props) => {
  const { card, onClick, url } = props;

  const cardStyle = {
    position: 'absolute',
    top: card.y,
    left: card.x,
    width: card.width + 'px',
    height: card.height + 'px',
    backgroundColor: card.backgroundColor,
    transform: card.transform,
  };

  return (
    <div
      style={cardStyle}
      className={cx(
        styles.card,
        !card.close && styles.opened,
        card.complete && styles.matched
      )}
    >
      <div
        className={styles.front}
        onClick={() => {
          if (onClick) onClick();
        }}
      >
        ?
      </div>
      <div className={styles.back}>
        <img style={{ borderRadius: '10px' }} src={url} />
      </div>
    </div>
  );
};
export default Card;
