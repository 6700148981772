import React from 'react';
import styles from './RenderView.module.scss';
import { cx } from '../../../helpers/classNames.helper';

const RenderView = (props) => {
  return (
    <div
      id="webglcontainer"
      className={cx(styles.host, props.hidden && styles.hidden)}
    ></div>
  );
};
export default RenderView;
