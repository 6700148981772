import React, { useState } from 'react';
import Cursor from '../../1_atom/Cursor/Cursor';

import useDimensions from 'react-cool-dimensions';

import styles from './StartButton.module.scss';

let gPosLastX = 0;
let gPosX = 0;
let gPosLastY = 0;
let gPosY = 0;
let gHitStart = null;
let gLastMode = null;

const scale = window.devicePixelRatio;
const padding = 100;

const StartButton = (props) => {
  const { mouseData, onClick } = props;

  const { observe, width, height } = useDimensions({
    onResize: ({ height, width }) => {
      gHitStart = null;
      gLastMode = null;

      // Triggered whenever the size of the target is changed...

      // set start button
      setButton({
        width,
        height,
        y: 0,
        x: 0,
      });
    },
  });

  const [button, setButton] = useState(null);

  // check border limitation mouseData
  let x = 0;
  let y = 0;

  const checkHit = (button) => {
    return (
      x > button.x &&
      x < button.x + button.width &&
      y > button.y &&
      y < button.y + button.height
    );
  };

  const factor = 100;

  const cornerRight = width + padding;
  const cornerLeft = 0 - padding;
  const cornerTop = 0 - padding;
  const cornerBottom = height + padding;

  if (mouseData) {
    if (mouseData.mode === 'move' || mouseData.mode === 'end') {
      x = mouseData.x * factor - gPosLastX;
      if (x > cornerRight) {
        if (mouseData.mode === 'end') gPosLastX += x - cornerRight;
        x = cornerRight;
      } else if (x < cornerLeft) {
        if (mouseData.mode === 'end') gPosLastX += x - cornerLeft;
        x = cornerLeft;
      }

      y = mouseData.y * factor - gPosLastY;
      if (y > cornerBottom) {
        if (mouseData.mode === 'end') gPosLastY += y - cornerBottom;
        y = cornerBottom;
      } else if (y < cornerTop) {
        if (mouseData.mode === 'end') gPosLastY += y - cornerTop;
        y = cornerTop;
      }
      gPosX = x;
      gPosY = y;
    }

    //    console.log('#', gHitCard ? true : false, mouseData.mode, gLastMode);

    // check start
    if (button) {
      if (mouseData.mode === 'move' || mouseData.mode === 'end') {
        gHitStart = checkHit(button);
      }
      if (gHitStart && mouseData.mode === 'click') {
        onClick();
      }
    }

    gLastMode = mouseData.mode;
  }

  const startButtonStyle = {
    position: 'absolute',
    left: button && button.x,
    top: button && button.y,
    width: button && button.width,
    height: button && button.height,
    backgroundColor: gHitStart ? 'rgba(20,20,20,0.75)' : 'rgba(0,0,0,0.75)',
  };

  return (
    <div className={styles.main} ref={observe}>
      <Cursor position={[gPosX, gPosY]} />
      <div
        onClick={() => {
          onClick();
        }}
        className={styles.button}
        style={startButtonStyle}
      >
        Start
      </div>
    </div>
  );
};

export default StartButton;
