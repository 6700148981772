import React from 'react';
import { Message, Progress } from 'semantic-ui-react';
import styles from './TimeCounter.module.scss';

const TimeCounter = (props) => {
  const { percent, time, max } = props;
  const newPercent = percent !== undefined ? percent : (time / max) * 100;

  const attention = newPercent < 10;
  const color = !percent && attention ? 'red' : 'green';

  return (
    <div className={styles.main}>
      <div className={attention ? styles.progressPulse : undefined}>
        <Progress color={color} percent={newPercent} size="medium"></Progress>
      </div>
    </div>
  );
};
export default TimeCounter;
