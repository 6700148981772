import React from 'react';

import styles from './MobileLose.module.scss';

const MobileLose = (props) => {
  return (
    <div className={styles.main}>
      <h1>Leider verloren</h1>
      <h1>Ausgeloggt!</h1>
    </div>
  );
};

export default MobileLose;
