import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';
import { Route, Switch, withRouter } from 'react-router-dom';
import RestAPI from '../../../../RestAPI.js';
import { io } from 'socket.io-client';

import styles from './MobileMain.module.scss';
import MobilePlay from '../MobilePlay/MobilePlay.js';
import MobileLose from '../MobileLose/MobileLose.js';
import MobileWin from '../MobileWin/MobileWin.js';
import MobileInit from '../MobileInit/MobileInit.js';
import MobileInfo from '../MobileInfo/MobileInfo.js';

import { cx } from '../../../../helpers/classNames.helper';

const startDevelopStatus = null; //'init';

class MobileMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      session: null,
      serverConnect: false,
      status: 'init', // init, toolate, play, loose, win, wincode, end, timeout, invalid, bookmarked
    };
  }

  async componentDidMount() {
    if (startDevelopStatus) {
      this.setState({ status: startDevelopStatus });
      return;
    }

    // get session_uuid or play_uuid
    const uuid = window.location.pathname.match(/\/([^\/]+)\/?$/)[1];

    RestAPI.init().then(async () => {
      // check session via RestAPI

      if (this.props.register) {
        //
        // Register (first call)
        //
        this.setState({ session_uuid: uuid });

        // from qr code
        const result = await RestAPI.registerSession(uuid);
        if (!result.ok) {
          if (result.message === 'playing') {
            this.setState({ status: 'toolate' });
          } else {
            this.setState({ status: result.message });
          }
        } else {
          // change route with play_uuid
          this.props.history.push('/mobileplay/' + result.session.play_uuid);
          this.playSession(result.session.play_uuid);
        }
      } else {
        //
        // Play
        //
        this.playSession(uuid);
      }
    });
  }

  async playSession(uuid) {
    const result = await RestAPI.playSession(uuid);
    if (!result || !result.session) {
      this.setState({ status: 'invalid' });
      return;
    }

    if (!result.socketSession || result.session.ended) {
      if (result.session.finished && result.session.code) {
        this.setState({
          status: 'win',
          data: {
            code: result.session.code,
          },
        });
      } else {
        this.setState({
          status: 'loose',
        });
      }
      return;
    }
    this.setState({
      session_uuid: result.session.uuid,
      session: result.session,
    });

    // socket session valid -> try to connect
    this._socketio = io(RestAPI.getSocketIO() + '/mobile', {
      path: '/api_playit2/socket.io',
      reconnection: true,
    });
    this._socketio.on('connect', () => {
      this.setState({ serverConnect: true });
      this._socketio.emit(
        'registerMobile',
        {
          session_uuid: result.session.uuid,
        },
        (result) => {
          if (result && result.ok === true) {
            this.setState({ serverConnect: true, status: 'play' });
            this._socketio.emit('m2sConnected', { button: true });
          } else {
            this.setState({ status: 'invalid' });
          }
        }
      );
    });
    this._socketio.on('disconnect', (data) => {
      this.setState({ serverConnect: false });
    });
    this._socketio.on('s2mStatus', (data) => {
      if (data.status === 'loose' || data.status === 'win') {
        // because there is no solution to disable the iphone double touch zoom
        // while playing on to touc field, we have to use a hack
        //
        // reload the site with an other url
        //
        // from
        // https://playit-game.com/mobileplay/05eabc4e-1926-4917-a22a-8c6cb865a8ab
        //
        // to
        // https://playit-game.com/mobilefin/05eabc4e-1926-4917-a22a-8c6cb865a8ab
        window.location.assign(
          window.location.href.replace('mobileplay', 'mobilefin')
        );
      }
    });
  }

  render() {
    const { status, session_uuid, session, serverConnect, data } = this.state;

    //    status: 'init', // init, intro, play, loose, win, end, invalid
    const Pages = () => {
      switch (status) {
        case 'init':
          return <MobileInit />;
        case 'toolate':
          return <MobileInfo mode={'toolate'} />;
        case 'play':
          return (
            <MobilePlay
              socketio={this._socketio}
              session_uuid={session_uuid}
              session={session}
            />
          );
        case 'loose':
          return <MobileLose />;
        case 'win':
        case 'wincode':
          return <MobileWin data={data} />;
        case 'timeout':
        default:
          return <MobileInfo mode={'invalid'} status={status} />;
      }
    };

    return (
      <div
        className={
          status === 'win' ? cx(styles.main, styles.main_win) : styles.main
        }
      >
        <div className={styles.first_sec}>
          <div className={styles.title}>PAARSUCHE</div>
          {status !== 'win' && (
            <div className={styles.felix}>
              <img
                alt="felix"
                src={process.env.PUBLIC_URL + '/assets/felix.png'}
              ></img>
            </div>
          )}
        </div>

        <div className={styles.second_sec}>
          <Switch>
            <Route
              path="*"
              render={(props) => {
                return (
                  <div>
                    <Pages />
                    {process.env.NODE_ENV !== 'production' &&
                      !startDevelopStatus && (
                        <div className={styles.debuginfo}>
                          <Message color={'red'}>
                            <div>Status: {status}</div>
                            <div>
                              Server: {serverConnect ? 'connected' : ''}
                            </div>
                            <div>Session uuid: {session_uuid}</div>
                          </Message>
                        </div>
                      )}
                  </div>
                );
              }}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

export default withRouter(MobileMain);
