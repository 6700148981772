const initialState = {
  apibase: null,
  debugMode: false,
  xstate: null,
  activePath: '',
};

const reducerMain = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_APIBASE':
      return {
        ...state,
        apibase: action.apibase,
      };
    case 'SET_PATH':
      return {
        ...state,
        activePath: action.activePath,
      };
    case 'SET_XSTATE':
      return {
        ...state,
        xstate: action.xstate,
      };
    case 'SET_DEBUG_MODE':
      return {
        ...state,
        debugMode: action.debugMode,
      };
    default:
      return state;
  }
};

export default reducerMain;
