import React from 'react';
import { Modal, Segment, Header, Button } from 'semantic-ui-react';

const ModalOkCancel = ({ open, text, color, onOK, onCancel }) => (
  <div style={{ width: '400px' }}>
    <Modal open={open} size={'tiny'}>
      <Header icon="delete" content="Delete Station?" />
      <Modal.Content>
        <Segment color={color ? color : null}>{text}</Segment>
        <Button
          onClick={() => {
            if (onOK) onOK();
          }}
        >
          ok{' '}
        </Button>
        <Button
          onClick={() => {
            if (onCancel) onCancel();
          }}
        >
          cancel{' '}
        </Button>
      </Modal.Content>
    </Modal>
  </div>
);

export default ModalOkCancel;
