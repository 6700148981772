import React, { Component } from 'react';

import { connect } from 'react-redux';
import RestAPI from '../../../RestAPI';
import styled from 'styled-components';
import { Progress, Popup, Modal } from 'semantic-ui-react';

const UploadDiv = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  & > input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
  }
`;

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
    };
  }

  async uploadFiles(files) {
    this.setState({ error: '', uploading: true });

    if (files) {
      if (files.length > 0) {
        const result = await RestAPI.uploadMedia(
          files[0],
          this.props.center.uuid
        );
        this.setState({ uploading: false });
        this.props.onChanged(result.data);
      }
    }
  }

  render() {
    const { label, id } = this.props;
    const { uploading } = this.state;

    const htmlID = id ? id : 'id';

    return (
      <div>
        <Popup
          content={label}
          trigger={
            <UploadDiv type="file1" placeholder="Upload">
              <label htmlFor={htmlID} class="ui mini icon button">
                <i aria-hidden="true" class="upload icon"></i>
              </label>

              <input
                type="file"
                id={htmlID}
                name="file"
                accept="image/*|video/*"
                onChange={(event) => {
                  console.log('## click', label);
                  this.uploadFiles(event.target.files);
                }}
              />
            </UploadDiv>
          }
        />
        <Modal size={'mini'} open={uploading} dimmer="blurring">
          <Modal.Header>Uploading</Modal.Header>
          <Modal.Content>
            <Progress percent={100} indicating />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

Upload.defaultProps = {};

Upload = connect(
  (state) => ({
    center: state.center.center,
    media: state.media,
  }),
  {} // bind account loading action creator
)(Upload);

export default Upload;
