import React from 'react';

import styles from './MobileInfo.module.scss';

const MobileInfo = (props) => {
  const { mode, status } = props;
  if (!mode) return <div></div>;
  switch (mode) {
    case 'toolate':
      return (
        <div className={styles.main}>
          Login nicht möglich!
          <p />
          Es spielt ein anderer Spieler!
          <p />
          Bitte versuche es später
          <p />
          noch einmal!
          <p />
        </div>
      );
    case 'invalid':
    default:
      return (
        <div className={styles.main}>
          {'ungültig'}
          <br />
          Code: {status}
        </div>
      );
  }
};

export default MobileInfo;
