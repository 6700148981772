import React, { Component } from 'react';

import { Menu } from 'semantic-ui-react';
import { includesOneOf } from '../../../helpers/scopecheck';
import { connect } from 'react-redux';
import RestAPI from '../../../RestAPI';
import store from '../../../redux/store';
import {
  actionSetUser,
  actionSetUsers,
} from './../../../redux/actions/actionUser';

class AdminMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePath: 'settings',
    };
  }

  handleItemClick = (item) => {
    const login = this.props.login;
    const isAdmin = includesOneOf(login.scope, ['admin','root']);

    switch (item) {
      case 'settings':
        if (isAdmin) {
          store.dispatch(actionSetUser(null));
          RestAPI.user(login.uuid);
          RestAPI.centers();
          this.props.history.push(`/admin/settings/${login.uuid}`);
        }
        break;
      case 'centers':
        RestAPI.centers();
        RestAPI.users();
        this.props.history.push(`/admin/${item}`);
        break;
      case 'users':
        if (isAdmin) {
          store.dispatch(actionSetUser(null));
          RestAPI.user(login.uuid);
          this.props.history.push(`/admin/${item}`);
        } else {
          RestAPI.users();
        }
        break;
      case 'stations':
        RestAPI.users();
        RestAPI.games();
        this.props.history.push(`/admin/${item}`);
        break;
      case 'sessions':
        RestAPI.sessions(this.props.center.uuid);
        this.props.history.push(`/admin/${item}`);
        break;
      case 'support':
        this.props.history.push(`/admin/${item}`);
        break;
      default:
        break;
    }
    this.setState({ activePath: item });
  };

  render() {
    const { login, center } = this.props;
    const { activePath } = this.state;

    const scope = login && login.scope;

    const isAdmin = includesOneOf(scope, ['admin']);
    const isRoot = includesOneOf(scope, ['root']);

    return (
      <Menu fluid vertical size="huge" style={{ height: '100%' }}>
        {isRoot && (
          <Menu.Menu>
            <Menu.Item
              name="Centers"
              active={activePath === 'centers'}
              onClick={() => this.handleItemClick('centers')}
            />
            {center && (
              <Menu.Item
                name="Admins"
                active={activePath === 'users'}
                onClick={() => this.handleItemClick('users')}
              />
            )}
            {center && (
              <Menu.Item
                name="Stations"
                active={activePath === 'stations'}
                onClick={() => this.handleItemClick('stations')}
              />
            )}
            {center && (
              <Menu.Item
                name="Sessions"
                active={activePath === 'sessions'}
                onClick={() => this.handleItemClick('sessions')}
              />
            )}
            <Menu.Item
              name="Support"
              active={activePath === 'support'}
              onClick={() => this.handleItemClick('support')}
            />
          </Menu.Menu>
        )}
        {isAdmin && (
          <Menu.Menu>
            <Menu.Item
              name="Settings"
              active={activePath === 'settings'}
              onClick={() => this.handleItemClick('settings')}
            />
            <Menu.Item
              name="Stations"
              active={activePath === 'stations'}
              onClick={() => this.handleItemClick('stations')}
            />
            <Menu.Item
              name="Sessions"
              active={activePath === 'sessions'}
              onClick={() => this.handleItemClick('sessions')}
            />
            <Menu.Item
              name="Support"
              active={activePath === 'support'}
              onClick={() => this.handleItemClick('support')}
            />
          </Menu.Menu>
        )}
      </Menu>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
AdminMenu = connect(
  (state) => ({
    login: state.login.login,
    center: state.center.center,
  }),
  {} // bind account loading action creator
)(AdminMenu);

export default AdminMenu;
