import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Icon, Button, Message, Popup, Card } from 'semantic-ui-react';

import { connect } from 'react-redux';

import styles from './Support.module.scss';

class Support extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className={styles.main}>
        <Message>
          <Message.Header>Support</Message.Header>
          <br />
          <p> Charamel GmbH</p>
          <br />
          Email: contact@charamel.com
          <br />
          Tel: ++49 221 336640
          <br />
          Tel: ++49 157 5068 1588
          <br />
        </Message>
      </div>
    );
  }
}

// You have to connect() to any reducers that you wish to connect to yourself
Support = connect(
  (state) => ({
    center: state.center.center,
    centers: state.center.centers,
    media: state.media,
  }),
  {}
)(Support);

export default withRouter(Support);
