const initialState = {
  sessions: [],
  session: null,
};

const reducerSession = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SESSION':
      return {
        ...state,
        session: action.session,
      };
    case 'SET_SESSIONS':
      return {
        ...state,
        sessions: action.sessions,
      };
    default:
      return state;
  }
};

export default reducerSession;
